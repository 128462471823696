<template>
  <div class="responsive-page" ref="scrollablePageMobile" @scroll="onScroll">
    <div class="page-wrapper">
      <div class="page-and-header" ref="scrollablePage" @scroll="onScroll">
        <div v-show="appLoading" class="loader-container">
          <loader />
        </div>

        <router-view v-show="!appLoading"/>
        <bottom-corner-popup/>
      </div>
      <div class="footer-wrapper">
        <copyright-footer/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '@/event-bus/event-bus';
import BaseUI from '@riseupil/base-ui';
import BottomCornerPopup from '@/bottom-corner-popup/BottomCornerPopup';
import MobileMessagesUtils from '@/utils/mobileMessagesUtils.js';
import CopyrightFooter from '../../base-components/CopyrightFooter.vue';
import Loader from '../../base-components/Loader.vue';

export default {
  name: 'ResponsivePage',
  components: {
    BottomCornerPopup,
    CopyrightFooter,
    Loader,
  },

  created() {
    this.onResize();
    EventBus.$on('reset-scroll', this.resetScroll);
    EventBus.$on('scroll-bottom', this.scrollBottom);
    window.addEventListener('resize', this.onResize);
  },
  mounted() {
    MobileMessagesUtils.dispatchMessageToMobile(MobileMessagesUtils.MOBILE_MESSAGES_NAME.PAGE_MOUNTED, {});
  },
  beforeDestroy() {
    EventBus.$off('reset-scroll', this.resetScroll);
    EventBus.$off('scroll-bottom', this.scrollBottom);
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    ...mapGetters('loader', ['appLoading']),
  },
  methods: {
    onResize() {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    resetScroll() {
      this.$refs.scrollablePage.scrollTop = 0;
      this.$refs.scrollablePageMobile.scrollTop = 0;
    },
    scrollBottom() {
      this.$refs.scrollablePage.scrollTop = 10000;
      this.$refs.scrollablePageMobile.scrollTop = 10000;
    },
    onScroll(e) {
      BaseUI.BottomTabsPageBus.$emit('scroll', e.target);
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../scss/mixins';

.responsive-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  position: fixed;
  .page-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    .page-and-header {
      width: 100%;
      height: 100%;
      .loader-container {
        height: 100%;
      }
    }
    .footer-wrapper {
      display: none;
    }
  }
}
@media (min-width: 430px) {
  .responsive-page {
    background-color: $riseup_beige;
    .page-wrapper {
      width: 400px;
      display: flex;
      align-items: center;
      margin: calc(100vh*0.05) 0;
      .page-and-header {
        margin-bottom: 20px;
        background-color: $riseup_white;
        min-height: inherit;
        height: calc(100vh - 100vh*0.1 - 35px - 20px);
        border-radius: 3px;
        box-shadow: 0 2px 5px 0 rgba(166, 166, 166, 0.5);
        border: solid 1px #e7e7e7;
        overflow: scroll;
        overflow-x: hidden;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
      }
      ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
      .footer-wrapper {
        width: 100%;
        visibility: visible;
        display: inline-block;
        height: 35px;
      }
    }
  }
}
</style>
