<template>
  <div class="row-content">
    <div class="card-info">
      <img v-if=disabled :src="require('@/assets/icons/credit-card-icon-disabled.svg')">
      <img v-else :src="require('@/assets/icons/credit-card-icon.svg')">
      <div class="card-number" :class="{ 'disabled': disabled }">
        <span>{{ creditCard.accountNumberPiiValue }}</span>
        <span class="dots">••••</span>
      </div>
    </div>
    <span v-if="creditCard.accountNickname">{{ creditCard.accountNickname }}</span>
  </div>
</template>

<script>

export default {
  name: 'ConnectedCreditCard',
  props: {
    creditCard: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../scss/mixins';

.row-content {
  height: 72px;

  @include responsive-padding;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .card-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    .card-number {
      margin-right: 15px;
      font-size: 16px;
      font-weight: bold;

      .dots {
        margin-right: 5px;
      }

      &.disabled {
        color: $riseup_gray_2
      }
    }
  }
}

</style>
