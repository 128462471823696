<template>
  <div class="remove-predicted-wo-actual">
    <colored-line-popup :class="coloredLinePopupVariant" :close-action="closeAction">
      <template v-slot:top-content>
      <card-transaction-header :is-saving-transaction="isSavingTransaction" :expense="predictedTransaction.expense"
                    :transaction-category-class="transactionCategoryClass" :transaction-type="transactionType"/>
      </template>

      <template v-slot:bottom-content>
        <div class="description">
          <update-balance-date-data
                        :predicted-amount="predictedAmount"
                        :predicted-transaction="predictedTransaction"
                        :transaction-category-class="transactionCategoryClass" :is-income="isIncomeTransaction" />

          <div class="predicted-amount-text">מה לעשות?</div>
        </div>

        <selection-group :items="selectedOptions" @select="UpdateIsPermanent" class="margin-bottom-m margin-top-l"/>

        <riseup-button class="save-button"
                       :action="saveBalanceDate"
                       :variant="'primary'"
                       :size="'slim'"
                       :title="'שמירה'"
                       :disabled="!isSelected"/>
      </template>

      <template v-slot:footer>
        <div class="cancel" v-on:click="onCancelPredictedTransaction">
          <span class="delete-text">למחוק את ה{{transactionType}}</span>
        </div>
      </template>
    </colored-line-popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapState } from 'vuex';
import EventBus from '@/event-bus/event-bus';

import Segment from '@/Segment';
import BalanceDateUpdatedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/BalanceDateUpdatedToaster';
import UpdateBalanceDateData from './UpdateBalanceDateData';
import CardTransactionHeader from './CardTransactionHeader';

const INCOME_TEXT = 'הכנסה';
const EXPENSE_TEXT = 'הוצאה';

export default {
  name: 'UpdateEnvelopeBalanceDate',
  components: {
    UpdateBalanceDateData,
    CardTransactionHeader,
    ColoredLinePopup: BaseUI.ColoredLinePopup,
    RiseupButton: BaseUI.RiseupButton,
    SelectionGroup: BaseUI.SelectionGroup,

  },
  props: {
    predictedTransaction: {
      type: Object,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
    nextAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      amount: Math.abs(this.predictedTransaction.predictedAmount),
      isPermanent: true,
      isSelected: false,
    };
  },
  created() {
    Segment.trackUserInteraction('EditEmptyEnvelopeShown', {
      predictedTransaction: this.predictedTransaction,
    });
  },
  computed: {
    ...mapState('cashflowView', ['presentedBudgetDate', 'cashflowStartDay']),
    predictedAmount() {
      return Math.abs(this.predictedTransaction.predictedAmount);
    },
    coloredLinePopupVariant() {
      if (this.isSavingTransaction) {
        return 'savings';
      }
      return this.isIncomeTransaction ? 'success' : 'fixed';
    },
    transactionCategoryClass() {
      if (this.isSavingTransaction) {
        return 'savings';
      }
      return this.isIncomeTransaction ? 'income' : 'fixed';
    },
    isIncomeTransaction() {
      return this.predictedTransaction.isIncome;
    },
    isSavingTransaction() {
      return !!this.predictedTransaction.isSaving;
    },
    transactionType() {
      return this.isIncomeTransaction ? INCOME_TEXT : EXPENSE_TEXT;
    },
    selectedOptions() {
      return [
        {
          title: 'לחכות עד סוף החודש,\nזה שינוי חד פעמי בתאריך',
        },
        {
          title: 'לחכות עד סוף החודש,\n התאריך השתנה באופן קבוע',
        },
      ];
    },
  },
  methods: {
    ...mapActions('editCashflow', ['updateEnvelopeBalanceDate']),
    onCancelPredictedTransaction() {
      Segment.trackUserInteraction('UpdateEnvelopeBalanceDateCancelPredictionClicked', {
        predictedTransaction: this.predictedTransaction,
      });
      this.nextAction();
    },
    async saveBalanceDate() {
      Segment.trackUserInteraction('UpdateEnvelopeBalanceDateClicked', {
        envelopeId: this.predictedTransaction.envelopeId,
        amount: this.amount,
        isIncome: this.isIncomeTransaction,
        predictedAmount: this.predictedAmount,
        cashflowMonth: this.presentedBudgetDate,
        isPermanent: this.isPermanent,
      });
      await this.updateEnvelopeBalanceDate({
        sequenceId: this.predictedTransaction.sequenceId,
        envelopeId: this.predictedTransaction.envelopeId,
        cashflowMonth: this.presentedBudgetDate,
        isPermanent: this.isPermanent,
      });
      this.closeAction();
      EventBus.$emit('reset-scroll');

      EventBus.$emit('open-toaster', {
        component: BalanceDateUpdatedToaster,
      });
    },
    UpdateIsPermanent(index) {
      this.isPermanent = index === 1;
      this.isSelected = true;
    },
  },
};
</script>

<style lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '@/scss/category-color-mixins';

  .remove-predicted-wo-actual {
    text-align: right;

    .description {
      line-height: 23px;
      font-weight: bold;
      font-size: 19px;
      @include category-color;

      .predicted-amount-text {
        margin-bottom: -18px;
        margin-top: 26px;
      }
    }

    .save-button {
      width: 100%;
    }

    .cancel {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .delete-text {
        font-weight: normal;
        font-size: 18px;
        color: $riseup_black;
      }
    }
  }

</style>
