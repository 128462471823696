<template>
  <div class="action whatsapp-action" @click="$emit('click')">
    <span>עדיף לי בוואטסאפ</span>
    <img class="whatsapp-icon" :src="require('@/assets/icons/whatsapp-icon.svg')"/>
  </div>
</template>

<script>
export default {
  name: 'WhatsappSendAgain',
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.whatsapp-action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;

  .whatsapp-icon {
    height: 16px;
    margin-bottom: 3px;
  }
}

.action {
  margin: 20px 0px;
  color: $riseup_blue;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  text-align: center;

  :active {
    color: $riseup_dark_blue;
  }
}
</style>
