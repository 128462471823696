<template>
  <div class="change-transaction-category-settings-popup">
    <popup :buttons="buttonOptions"
           :back-action="back">
      <template v-slot:title>
        תמיד לשים ב<span class="selected-category" :class="selectedCategory.cashflowCategory">{{ selectedCategory.pluralLabel }}</span>
        <template v-if="(hasPaymentAppAddressee && !isTransferTransaction)">
          <div>העברות ביט ופייבוקס ל-</div>
        </template>
        <template v-else-if="(hasPaymentAppAddressee && isTransferTransaction)">
          <div>העברות ל-</div>
        </template>
        <template v-else>
          <div>הוצאות מ-</div>
        </template>
        <span class="selected-category" :class="selectedCategory.cashflowCategory">
          {{ hasPaymentAppAddressee ? transaction.paymentAppAddressee : transaction.businessName }}
        </span><span>?</span>
      </template>
    </popup>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import EventBus from '@/event-bus/event-bus';
import TransactionMovedToaster from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/TransactionMovedToaster.vue';
import cashflowViewConsts from '@/constants/cashflow-view';
import ChangeTransactionCategoryPopup from './ChangeTransactionCategoryPopup.vue';
import ChangeTransactionCategorySummaryPopup from './ChangeTransactionCategorySummaryPopup.vue';
import Segment from '../../../../../../../../../../Segment';

const PAYMENT_METHODS_CATEGORIES = [
  'העברות',
  'שיק',
  'מזומן',
];

const BUSINESS_NAMES_THAT_SKIPS_POPUP = [
  'BIT',
  'העברה ב BIT בנה"פ',
  'העברה בBIT',
  'העברת כסף bit',
  'BIT                      beit dagan   IL',
  'bit',
  'BIT                    >beit dagan   ISR',
  'BIT >beit dagan ISR',
  'BIT beit dagan IL',
  'BIT DIGITAL קניה טלפון ני',
  'bit העברת כסף',
  'BIT- BANK HAPOALIM',
  'BIT- BANK HAPOALIM >beit dagan ISR',
  'BIT-BANK HAPOA',
  'bit-bank hapoa',
  'BITהעברה ב',
  'BIT-BANK HAPOALIM beit dagan IL',
  'BIT-BANK HAPOALIM        beit dagan   IL',
  'BIT-BANK HAPOALIM',
  'בנה"פ BIT העברה ב',
  'העברה ב BIT',
  'העברה ב BIT בנ',
  'העברה ב bit בנ',
  'PAYBOX לקוחות פרטיים שב"א',
  'paybox',
  'PAYBOX TEL AVIV IL',
  'PAYBOX tel aviv IL',
  'PAYBOX LTD',
  'PAYBOX >tel aviv ISR',
  'PAYBOX                 tel aviv      IL',
  'PAYBOX                 tel aviv',
  'PAYBOX                 >tel aviv     ISR',
  'PAYBOX                   tel aviv     IL',
  'PAYBOX',
  'פייבוקס',
  'פייבוקס-י',
  'פייבוקס בע"מ',
  'פייבוקס בע"מ (י)',
  'מפייבוקס שלי',
  'מפייבוקס שלי-י',
  '‏פייבוקס בע"מ (י)',
  'זיכוי מפייבוקס',
  'מפייבוקס ש',
];

export default {
  name: 'ChangeTransactionCategorySettingsPopup',
  components: {
    Popup: BaseUI.Popup,
  },
  created() {
    Segment.trackUserInteraction('ChangeTransactionCategorySettingsPopup_Shown', {
      businessName: this.transaction.businessName,
      originalCategory: this.transaction.expense,
      transactionId: this.transaction.transactionId,
    });
    if (this.shouldSkipPopup) {
      this.onSave(this.ENRICHMENT_INPUT_TYPES.TRANSACTION_ID);
    }
  },
  computed: {
    ...mapState('editTransaction', ['transaction', 'categoryRuleType']),
    ...mapGetters('editTransaction', ['selectedCategory', 'ENRICHMENT_INPUT_TYPES', 'categoryToVariant', 'originalCategory', 'valueForPredicate']),
    ...mapGetters('featureFlags', ['enableMoveTransactionsByPaymentAppAddressee']),
    buttonOptions() {
      return [{
        title: 'כן, תמיד',
        size: 'slim',
        action: () => this.onSave(
          this.hasPaymentAppAddressee ? this.ENRICHMENT_INPUT_TYPES.PAYMENT_APP_ADDRESSEE : this.ENRICHMENT_INPUT_TYPES.BUSINESS_NAME,
          this.isAlwaysPrimaryOption,
        ),
        variant: this.alwaysOptionVariant,
        disabled: !this.transaction.businessName,
      }, {
        title: 'לא, רק את ההוצאה הזו',
        size: 'slim',
        action: () => this.onSave(this.ENRICHMENT_INPUT_TYPES.TRANSACTION_ID, !this.isAlwaysPrimaryOption),
        variant: this.onlyThisTimeOptionVariant,
      }];
    },
    alwaysOptionVariant() {
      return this.isAlwaysPrimaryOption ? 'primary' : 'secondary';
    },
    onlyThisTimeOptionVariant() {
      return this.isAlwaysPrimaryOption ? 'secondary' : 'primary';
    },
    isAlwaysPrimaryOption() {
      return !PAYMENT_METHODS_CATEGORIES.includes(this.transaction.expense) || this.hasPaymentAppAddressee;
    },
    hasPaymentAppAddressee() {
      return this.enableMoveTransactionsByPaymentAppAddressee && !!this.transaction.paymentAppAddressee;
    },
    isTransferTransaction() {
      return this.transaction.sourceType === 'checkingAccount';
    },
    shouldSkipPopup() {
      return !this.hasPaymentAppAddressee && BUSINESS_NAMES_THAT_SKIPS_POPUP.includes(this.transaction.businessName?.trim());
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('editCashflow', ['moveTransaction']),
    ...mapMutations('editTransaction', ['setCategoryRuleType']),
    back() {
      Segment.trackUserInteraction('ChangeTransactionCategorySettingsPopup_BackClicked', {
        businessName: this.transaction.businessName,
        originalCategory: this.transaction.expense,
        transactionId: this.transaction.transactionId,
      });
      this.$emit('close');
      this.openModal({
        component: ChangeTransactionCategoryPopup,
        props: {},
      });
    },
    onSave(type, isPrimaryOptionClicked) {
      Segment.trackUserInteraction('ChangeTransactionCategorySettingsPopup_AnswerClicked', {
        businessName: this.transaction.businessName,
        ruleType: type,
        transactionId: this.transaction.transactionId,
        selectedCategory: this.selectedCategory.expense,
        primaryOption: this.isAlwaysPrimaryOption ? 'always' : 'onlyThisTime',
        isPrimaryOptionClicked,
        popupSkipped: this.shouldSkipPopup,
      });
      this.setCategoryRuleType(type);
      this.$emit('close');

      if (this.originalCategory.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED) {
        this.openModal({
          component: ChangeTransactionCategorySummaryPopup,
          props: {},
        });
      } else {
        this.moveTransaction({
          originalCategory: this.originalCategory,
          selectedCategory: this.selectedCategory,
          transaction: this.transaction,
          categoryRuleType: this.categoryRuleType,
          valueForPredicate: this.valueForPredicate,
        });
        EventBus.$emit('open-toaster', {
          component: TransactionMovedToaster,
          props: {
            changedTransaction: this.transaction,
            targetCategory: this.selectedCategory,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../../../../scss/category-color-mixins';

  .change-transaction-category-settings-popup {
    .selected-category {
      @include category-color;
    }
  }
  span {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
  }

</style>
