<template>
  <div class="connected-credentials-name">
    <div class="header">
      <div class="source-description">
        <div class="checkmark-holder">
          <img src="/images/v.svg" class="checkmark">
        </div>
        <div class="source-name-wrapper">
          <div class="card-title">{{ sourceName }}</div>
          <div class="subtitle">{{ name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConnectedCredentialsName',
  props: {
    source: Object,
  },
  computed: {
    sourceName() {
      return this.source.displayName;
    },
    name() {
      return this.source.name;
    },
    accounts() {
      return this.source.accounts;
    },
    type() {
      return this.source.type;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../scss/mixins';

  .connected-credentials-name {
    text-align: right;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .source-description {
        display: flex;

        .source-name-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .card-title {
            @include card-title;
            margin-bottom: 5px;
          }
        }

        .checkmark-holder {
          margin-left: 15px;
        }
      }
    }

  }
</style>
