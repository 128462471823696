<template>
  <div class="cashflow-expense-progress">
    <progress-bar :right-label="upToNowLabelFinal"
                  :right-amount="upToNowAmount"
                  :left-label="recommendedOrExpectedLabel"
                  :left-amount="recommendedOrExpectedAmount"
                  :show-warning-if-exceeding-amount="showWarningIfExceedingBudget"
                  :cashflow-category="cashflowCategory"
                  :grayed-out="outOfDate"
                  :hide-left-label="hideLeftLabel"
                  :animate-in-viewport="!previousMonth"
                  :placeholder="placeholderString"
                  :show-filter="showFilter"
                  :filtered-amount="filteredAmount" />
  </div>
</template>

<script>
import ProgressBar from './ProgressBar.vue';

export default {
  name: 'CashflowExpenseProgress',
  components: {
    ProgressBar,
  },
  props: {
    upToNowLabel: {
      type: String,
      required: false,
    },
    upToNowAmount: {
      type: Number,
      required: false,
    },
    recommendedLabel: {
      type: String,
      required: false,
    },
    recommendedOrExpectedAmount: {
      type: Number,
      required: false,
    },
    showWarningIfExceedingBudget: {
      type: Boolean,
      required: false,
    },
    blocked: {
      type: Boolean,
      required: false,
      default: false,
    },
    previousMonth: {
      type: Boolean,
      default: false,
    },
    outOfDate: {
      type: Boolean,
      required: false,
    },
    cashflowCategory: {
      type: String,
      required: false,
    },
    isIncome: {
      type: Boolean,
      required: false,
    },
    showFilter: {
      type: Boolean,
      required: false,
    },
    filteredAmount: {
      type: Number,
      required: false,
    },
  },
  computed: {
    isExceedingBudget() {
      return this.showWarningIfExceedingBudget && this.upToNowAmount > this.recommendedOrExpectedAmount;
    },
    hideLeftLabel() {
      return !this.recommendedLabel && this.previousMonth;
    },
    recommendedInOrOut() {
      if (this.isIncome) {
        return 'להיכנס';
      }
      return 'לצאת';
    },
    recommendedOrExpectedLabel() {
      if (this.recommendedLabel) {
        return this.recommendedLabel;
      }
      if (this.previousMonth) {
        return `צפוי היה ${this.recommendedInOrOut}`;
      }
      return this.isExceedingBudget ? `צפוי היה ${this.recommendedInOrOut}` : `צפוי ${this.recommendedInOrOut}`;
    },
    actualInOrOut() {
      if (this.isIncome) {
        return 'נכנס';
      }
      return 'יצא';
    },
    upToNowLabelFinal() {
      if (this.upToNowLabel) {
        return this.upToNowLabel;
      }
      return this.actualInOrOut;
    },
    placeholderString() {
      if (this.blocked) { return '***'; }
      return '';
    },
  },
};
</script>

<style lang="scss">

.cashflow-expense-progress {
  margin-bottom: 20px;
}

</style>
