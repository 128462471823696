<template>
  <full-screen-modal :close-action="closeAction" :back-action="backAction">
    <template v-slot:content>
      <div id="bankSelection" class="bank-selection">
        <div class="description">
          <div class="title ri-large-headline ri-alte">{{ title }}</div>
          <security-info-tooltip  v-if="sourceType === 'bank'" class="tool-tip-wrapper" />
          <info-tool-tip  v-else class="tool-tip-wrapper"
                          :text="'איך לדעת מה חברת האשראי שצריך לחבר? הכנו לך '"
                          :popup-text="'כאן הסבר מדויק.'"
                          :popup-component="ccIssuerHelpPopupComponent"/>
        </div>
        <separator/>
        <div v-for="source in sourceList" :key="source.sourceName">
          <account-selection-item :source="source" :on-selection="handleSourceSelection"/>
          <separator/>
        </div>
      </div>
    </template>
  </full-screen-modal>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';

import { getSourceConfigurationBySourceName, sortedCardSources, sortedCashSources } from '@/constants/sourcesConfiguration';
import Segment from '@/Segment';
import FullScreenModal from '@/base-components/FullScreenModal';
import EventBus from '@/event-bus/event-bus';
import UnsupportedBankPopup from '@/base-components/onboarding/UnsupportedBankPopup';
import AccountSelectionItem from '@/base-components/onboarding/AccountSelectionItem';
import InfoToolTip from '@/base-components/onboarding/InfoToolTip';
import CCIssuerHelpPopup from '@/base-components/onboarding/help-popups/CCIssuerHelpPopup';
import SecurityInfoTooltip from '@/base-components/SecurityInfoTooltip';
import BankAccounts from '@/mixins/BankAccounts';
import OpenBankingMixin from '@/base-components/onboarding/openBanking/OpenBankingMixin.vue';

export default {
  name: 'SourceSelectionModal',
  components: {
    FullScreenModal,
    InfoToolTip,
    SecurityInfoTooltip,
    AccountSelectionItem,
    Separator: BaseUI.Separator,
  },
  mixins: [BankAccounts, OpenBankingMixin],
  props: {
    sourceType: {
      type: String,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
    setSelectedSource: {
      type: Function,
      required: true,
    },
    backAction: {
      type: Function,
      required: false,
    },
  },
  mounted() {
    EventBus.$emit('reset-scroll');
  },
  computed: {
    sourceList() {
      let sourceOptions = [];
      if (this.sourceType === 'cash') {
        sourceOptions = sortedCashSources();
      } else if (this.sourceType === 'bank') {
        sourceOptions = this.sortedCombinedBankSources;
      } else {
        sourceOptions = sortedCardSources();
      }
      return sourceOptions.filter(source => this.isSourceOpenToCustomer(source.id));
    },
    title() {
      if (this.sourceType === 'bank') {
        return 'לאיזה בנק שייך החשבון שנחבר לשירות?';
      }
      return 'לאיזו חברת אשראי שייך הכרטיס שנחבר לשירות?';
    },
    ccIssuerHelpPopupComponent() {
      return CCIssuerHelpPopup;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    async handleSourceSelection(sourceName) {
      const source = getSourceConfigurationBySourceName(sourceName);
      if (this.isSourceUnSupported(source)) {
        this.openModal({
          component: UnsupportedBankPopup,
          props: {
            sourceName,
            bankDisplayName: source.displayName,
            waitingListName: source.waitingListName,
          },
          showLastModal: true,
        });
      } else {
        const eventName = source === 'card' ? 'CardNameClicked' : 'BankNameClicked';
        Segment.trackUserInteraction(eventName, { sourceName });
        this.setSelectedSource(source);
      }
    },
    isSourceUnSupported(source) {
      return source && source.unsupported;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';

.bank-selection {
  .description {
    @include responsive-padding;
    margin-top: 40px;
    color: $riseup_black;

    .title {
      text-align: right;
    }

    .tool-tip-wrapper {
      text-align: right;
      margin: 20px 0 40px;
    }
  }

  .credentials-card {
    margin-bottom: 25px;
  }
}
</style>
