<template>
  <div class="update-balance-date-data">
    <div>{{transactionTypeText}}</div>
    <div class="description" :class="transactionCategoryClass">{{ predictedTransaction.name }}</div>
    <span>בסכום של</span>
    <span>{{ predictedAmount | formatAmountWithFraction | shekel }}</span>
    <div v-if="isBalanceDateChangedUtilTheEndOfTHeMonth">
      <span>{{ customerInputText }}</span><span class="description" :class="transactionCategoryClass">{{formatDate(cashflowLastDate)}}</span>
    </div>
    <div v-else-if="isBalanced" class="description" :class="transactionCategoryClass">{{ balancedText }}</div>
    <div v-else>
      <span>{{ predictedText }}</span><span class="description" :class="transactionCategoryClass">{{transactionDate}}</span>
    </div>
  </div>
</template>
<script>
import * as _ from 'lodash';
import moment from 'moment/moment';
import cashflowHistory from '@/store/utilities/cashflow-history';
import { mapState } from 'vuex';

export default {
  name: 'UpdateBalanceDateData',
  props: {
    predictedAmount: {},
    predictedTransaction: {},
    transactionCategoryClass: {},
    isIncome: {},
  },
  computed: {
    ...mapState('cashflowView', ['presentedBudgetDate']),
    transactionTypeText() {
      return this.isIncome ? 'הכנסה מ:' : 'החיוב על:';
    },
    customerInputText() {
      return this.isIncome ? 'צפויה להגיע עד ה־' : 'צפוי לרדת עד ה־';
    },
    balancedText() {
      return this.isIncome ? 'לא צפויה להכנס החודש' : 'לא צפוי לרדת החודש';
    },
    predictedText() {
      return this.isIncome ? 'צפויה להכנס ב־' : 'צפוי לרדת ב־';
    },
    isBalanceDateChangedUtilTheEndOfTHeMonth() {
      return this.predictedTransaction.isBalanceDateChanged && !this.isBalanced;
    },
    isBalanced() {
      return this.predictedTransaction.balanceDatePassed && _.isNil(this.predictedTransaction.actual);
    },
    transactionDate() {
      return this.formatDate(this.predictedTransaction.predictedTransactionDate);
    },
    cashflowLastDate() {
      return cashflowHistory.getCashflowLastDay(this.presentedBudgetDate);
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('D.M.YY');
    },
  },
};
</script>
<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/category-color-mixins';
.update-balance-date-data{
  text-align: right;

  .description {
    line-height: 23px;
    font-weight: bold;
    font-size: 19px;
    @include category-color;

    .predicted-amount-text {
      margin-bottom: -18px;
      margin-top: 26px;
    }
  }
}
</style>
