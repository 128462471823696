<template>
  <div class="month-year-picker">
    <input-dropdown :options="options"
                    :first-selected="date"
                    :on-select="onSelect"
                    class="cashflow-category-dropdown"/>
  </div>
</template>

<script>
import dateUtils from '@/utils/dates';
import { mapState } from 'vuex';
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';

const MAX_MONTHS_DIFF = 48;

export default {
  name: 'MonthYearPicker',
  components: {
    InputDropdown: BaseUI.InputDropdown,
  },
  data() {
    return {
      options: [],
      date: null,
    };
  },
  created() {
    const monthList = dateUtils.generateMonthsList(MAX_MONTHS_DIFF);
    this.options = monthList.map(item => ({ value: item, label: this.prettyName(item) }));
    if (this.currentPlan.deadline) {
      this.date = _.find(this.options, ({ value }) => this.prettyName(value) === this.prettyName(this.currentPlan.deadline));
    }
  },
  computed: {
    ...mapState('planAhead', ['currentPlan']),
  },
  methods: {
    prettyName(monthYear) {
      return dateUtils.getMonthAndYear(monthYear);
    },
    onSelect(option) {
      this.$emit('select', option.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.month-year-picker {
  ::v-deep .vs__dropdown-menu {
    position: absolute;
  }
}
</style>
