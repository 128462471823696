<template>
  <div :class="['create-subscription-flow-v2']">
    <header-page :header-props="headerPageProps">
      <template v-if="flowStep === SUBSCRIPTION_FLOW_STEPS.creditCard">
        <!--We use v-show and not v-if because the credit-card component cannot be re-rendered-->
        <!--The fields in it are mounted by payme making rerendering heavy and destroying previous context-->
        <credit-card-v2
          v-show="flowStep === SUBSCRIPTION_FLOW_STEPS.creditCard" />
      </template>
      <done-v2 v-else-if="flowStep === SUBSCRIPTION_FLOW_STEPS.done" mode="create" />
    </header-page>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import { ACTION_TYPE } from '@riseupil/base-ui/src/components/ActionIcon/ActionIcon.consts';
import router from '@/router';
import BaseUI from '@riseupil/base-ui';
import { SUBSCRIPTION_TYPES } from '@/constants/subscription-state';
import CreditCardV2 from './CreditCardV2.vue';
import DoneV2 from './DoneV2.vue';
import Segment from '../../../../../Segment';

export default {
  name: 'SubscriptionAnnualDiscount',
  components: {
    CreditCardV2,
    DoneV2,
    HeaderPage: BaseUI.Pages.HeaderPage,
  },
  data() {
    return {
      headerPageProps: {
        rightActions: [{
          type: ACTION_TYPE.CLOSE,
          onClick: this.onClose,
        }],
        showLogo: true,
        backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
        logoColor: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
        textColor: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
        bottomBorder: true,
      },
    };
  },
  async created() {
    await this.setUseNewAnnualOffering(true);
    await this.fetchFirstBillingDate();
    await this.fetchSubscription();
    await this.fetchPricing();
    this.setStep(this.SUBSCRIPTION_FLOW_STEPS.creditCard);
    this.setType(SUBSCRIPTION_TYPES.YEARLY);
    Segment.trackUserGot('SubscriptionAnnualDiscount_Entered');
  },
  computed: {
    ...mapGetters('subscription', ['SUBSCRIPTION_FLOW_STEPS']),
    ...mapState('subscription', ['flowStep', 'type']),
  },
  methods: {
    ...mapActions('subscription', ['fetchFirstBillingDate', 'fetchPricing', 'fetchSubscription']),
    ...mapMutations('subscription', ['setStep', 'setType', 'setUseNewAnnualOffering']),
    onClose() {
      Segment.trackUserGot('SubscriptionAnnualDiscount_CloseClicked', { currFlowStep: this.flowStep });
      router.push({ path: '/account-settings', query: this.$route.query });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
