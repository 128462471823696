<template>
  <insight-factory v-if="insight" :insight="insight" />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Segment from '@/Segment';
import InsightFactory
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/InsightFactory.vue';
import insightsApi from '@/api/InsightsApi';

export default {
  name: 'OtsInsightsResults',
  components: { InsightFactory },
  props: {},
  data() {
    return {
      insight: null,
    };
  },
  async created() {
    Segment.trackUserInteraction('OTSIL_InsightsResultsEntered');
    this.startLoading('insightsOTS');
    if (!this.promptIdFromLatestPromptJob) {
      await this.getLatestPromptJob();
    }

    const insights = await insightsApi.fetchInsights();
    this.insight = insights.find(i => i.insightId === this.promptIdFromLatestPromptJob);
    this.doneLoading('insightsOTS');
  },
  computed: {
    ...mapState('ots', ['otsResults', 'promptIdFromLatestPromptJob']),
  },

  methods: {
    ...mapActions('loader', ['startLoading', 'doneLoading']),
    ...mapActions('ots', ['getOTSResult', 'getLatestPromptJob']),
  },
};
</script>

<style lang="scss" scoped>
</style>
