<template>
  <div class="ri-font-weight-bold">
    <span class="notice">שימו לב!</span> מעכשיו הקוד נשלח בSMS
  </div>
</template>

<script>
export default {
  name: 'SmsNotice',
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.notice {
  color: $riseup_red;
}
</style>
