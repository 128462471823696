const SESSION_STORAGE_KEY = 'mobile_app_prompt_closed';

export default {
  namespaced: true,
  state: {
    mobileAppPromptDismissed: false,
  },
  getters: {
    isMobileAppPromptDismissed: state => state.mobileAppPromptDismissed,
  },
  mutations: {
    setMobileAppPromptDismissed(state, value) {
      state.mobileAppPromptDismissed = value;
      sessionStorage.setItem(SESSION_STORAGE_KEY, value);
    },
  },
  actions: {
    initializeFromSession({ commit }) {
      const dismissed = sessionStorage.getItem(SESSION_STORAGE_KEY) === 'true';
      commit('setMobileAppPromptDismissed', dismissed);
    },
    dismissMobileAppPrompt({ commit }) {
      commit('setMobileAppPromptDismissed', true);
    },
  },
};
