import OtisApi from '../../api/Otis';

const OTS_PROMPTID_LOCAL_STORAGE_KEY = 'OTSILPromptId';
const state = {
  jobId: null,
  jobStatus: null,
  flowConfigId: null,
  promptIdFromLatestPromptJob: null,
  otsResults: [],
};

const getters = {
  OTSILPromptId: () => localStorage.getItem(OTS_PROMPTID_LOCAL_STORAGE_KEY),
  OTSILJobId: () => localStorage.getItem(OTS_PROMPTID_LOCAL_STORAGE_KEY),
};

const actions = {
  async createLLMJob({ commit }, { promptId, flowConfigId, executeNow }) {
    const { jobId } = await OtisApi.createLLMJob({ flowConfigId, promptId, executeNow });
    commit('setJobId', jobId);
  },
  async activateLLMJob({ state, dispatch, commit, getters }, { triggerScrape }) {
    if (!state.jobId) {
      await dispatch('getLatestPromptJob');
    }

    if (state.jobId) {
      await OtisApi.activateLLMJob(state.jobId, triggerScrape);
    }
  },
  async getOTSResult({ commit, dispatch, state }) {
    if (!state.jobId) {
      await dispatch('getLatestPromptJob');
    }
    const otsResults = await OtisApi.getOTSResult(state.jobId);
    commit('setOTSResults', otsResults);
  },
  async getLatestPromptJob({ dispatch }) {
    const { _id: jobId, promptId, flowConfigId, status } = await OtisApi.getLatestPromptJob();
    dispatch('setPromptJob', { jobId, status, promptId, flowConfigId });
  },
  async getJobByParams({ dispatch }, { flowConfigId, promptId }) {
    const { _id: jobId, status } = await OtisApi.getJobByParams({ flowConfigId, promptId });
    dispatch('setPromptJob', { jobId, status, promptId, flowConfigId });
  },
  async setPromptJob({ commit }, promptJob) {
    const { jobId, promptId, status, flowConfigId } = promptJob;
    commit('setJobId', jobId);
    commit('setPromptIdFromLatestPromptJob', promptId);
    commit('setJobStatus', status);
    commit('setFlowConfigId', flowConfigId);
  },
};

const mutations = {
  setJobId(state, jobId) {
    state.jobId = jobId;
  },
  setJobStatus(state, jobStatus) {
    state.jobStatus = jobStatus;
  },
  setPromptIdFromLatestPromptJob(state, promptId) {
    state.promptIdFromLatestPromptJob = promptId;
  },
  setOTSResults(state, otsResults) {
    state.otsResults = otsResults;
  },
  setFlowConfigId(state, flowConfigId) {
    state.flowConfigId = flowConfigId;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
