<template>
  <div class="connected-account-details">
    <span class="circle">{{ index + 1 }}</span>
    <span class="account-number">{{ typeof account === 'object' ? account.accountNumberPiiValue : account }}</span>
    <span class="card-stars" v-if="type === 'card'">****</span>
    <span class="not-connected" v-if="account.isExcluded"> [ לא מחובר לתזרים ]</span>
  </div>
</template>

<script>
export default {
  name: 'ConnectedAccountDetails',
  props: {
    index: Number,
    account: Object,
    type: String,
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../scss/mixins';

  .connected-account-details {
    text-align: right;
    height: 30px;
    padding-top: 15px;

    .circle {
      display: inline-block;
      height: 16px;
      width: 16px;
      line-height: 18px;
      font-weight: bold;
      font-size: 8px;
      vertical-align: middle;
      text-align: center;
      border-radius:8px;
      color: $riseup_white;
      background: $riseup_green;
      margin-left: 12px;
      margin-bottom: 3px;
    }

    .account-number {
      font-size: 16px;
    }

    &.excluded {
      color: $riseup_gray_2;

      .circle {
        background: $riseup_gray_1;
      }
    }

    .not-connected {
      margin-right: 10px;
    }
  }
</style>
