<template>
  <loader v-if="loading"/>
  <div ref="credentialsFlow" v-else class="create-credentials-flow">
    <source-type-selection-modal v-if="componentName === componentNames.sourceTypeSelectionModal"
                                 :close-action="closeAction"
                                 @source-type-selected="handleSourceTypeSelected"/>
    <source-selection-modal v-else-if="componentName === componentNames.sourceSelectionModal"
                            :source-type="sourceType || knownSourceType" :close-action="closeAction" :set-selected-source="setSelectedSource"
                            :back-action="backAction"/>
    <connection-flow-modal v-else-if="componentName === componentNames.connectionFlowModal" v-bind="connectionFlowModalProps"/>
    <credentials-connection-success v-else-if="componentName === componentNames.credentialsConnectionSuccess"
                                    :source-name="sourceToConnect" :source-type="sourceType" :close-action="closeAfterSuccess"/>
  </div>
</template>

<script>
import router from '@/router';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import SourceSelectionModal
  from '@/pages/responsive-pages/authenticated/onboard2/steps/create-credentials-flow/SourceSelectionModal';
import ConnectionFlowModal
  from '@/pages/responsive-pages/authenticated/onboard2/steps/create-credentials-flow/ConnectionFlowModal';
import CredentialsConnectionSuccess
  from '@/pages/responsive-pages/authenticated/onboard2/steps/create-credentials-flow/CredentialsConnectionSuccess';
import Segment from '@/Segment';
import _ from 'lodash';
import AsyncFallbackPopup from '@/pages/responsive-pages/authenticated/onboard2/AsyncFallbakPopup.vue';
import SourceTypeSelectionModal from './SourceTypeSelectionModal';

const componentNames = {
  sourceTypeSelectionModal: 'source-type-selection-modal',
  sourceSelectionModal: 'source-selection-modal',
  connectionFlowModal: 'connection-flow-modal',
  credentialsConnectionSuccess: 'credentials-connection-success',
};

export default {
  name: 'AddCredentialsFlow',
  data() {
    return {
      componentName: null,
      loading: false,
      sourceType: null,
      sourceToConnect: null,
      accountInvestigationFinished: false,
      nameAlreadyInUseError: false,
    };
  },
  components: {
    SourceTypeSelectionModal,
    SourceSelectionModal,
    ConnectionFlowModal,
    CredentialsConnectionSuccess,
  },
  props: {
    allowBackToSourceSelection: {
      type: Boolean,
      default: true,
    },
    sourceNameToAdd: {
      type: String,
      required: false,
    },
    sourceToUpdate: {
      type: Object,
      required: false,
    },
    updateMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    knownSourceType: {
      type: String,
      required: false,
    },
    closedBankingCredentialsId: {
      type: String,
      required: false,
    },
    preventOBkConnection: {
      type: Boolean,
      required: false,
      default: false,
    },
    preventLoadingCredentialsCheck: {
      type: Boolean,
      required: false,
      default: false,
    },
    showBackActions: {
      type: Boolean,
      default: true,
    },
  },
  async created() {
    if (!this.preventLoadingCredentialsCheck) {
      await this.fetchLoadingCredentialsState();
      await this.handleLoadingState();
    }

    if (this.sourceName) {
      this.sourceToConnect = this.sourceName;
      this.sourceType = getSourceConfigurationBySourceName(this.sourceName).type;
      this.componentName = this.componentNames.connectionFlowModal;
    } else if (this.knownSourceType) {
      this.componentName = this.componentNames.sourceSelectionModal;
    } else {
      this.componentName = this.componentNames.sourceTypeSelectionModal;
    }
    this.setClosedBankingCredentialsIdToUpdate(this.closedBankingCredentialsId);

    if (this.sourceToUpdate && this.sourceToUpdate.type) {
      this.sourceType = this.sourceToUpdate.type;
    }
  },
  computed: {
    ...mapState('credentials', ['credentialsValid', 'credentialsList',
      'credentialsSaveError', 'asyncTimeoutReached', 'loadingStartTime', 'isLoading']),
    ...mapState('websocket', ['socketMessage']),
    ...mapGetters('credentials', ['invalidCredentialsList', 'credentialsLoadingTooLong']),
    ...mapState('ots', ['jobId']),
    // We are not going to do the sync flow if there is more than 1 invalid creds (because no scrape happen)
    isSyncFlow() {
      return this.invalidCredentialsList.length < 2;
    },
    componentNames() {
      return componentNames;
    },
    componentsOrder() {
      return [
        componentNames.sourceTypeSelectionModal,
        componentNames.sourceSelectionModal,
        componentNames.connectionFlowModal,
        componentNames.credentialsConnectionSuccess,
      ];
    },
    sourceName() {
      return this.sourceNameToAdd || this.sourceToUpdate?.sourceName;
    },
    connectionFlowModalProps() {
      const propsToPass = {
        sourceType: this.sourceType,
        closeAction: this.closeAction,
        invalidCredentials: !this.credentialsValid,
        nameAlreadyUsedError: this.nameAlreadyInUseError,
        setNameAlreadyInUseError: nameAlreadyUsedError => { this.nameAlreadyInUseError = nameAlreadyUsedError; },
        validateName: this.validateName,
        saveCredentialsError: this.credentialsSaveError,
        next: this.goToNextComponent,
        sourceName: this.sourceToConnect,
        isLoading: this.isLoading,
        updateMode: this.updateMode,
        backAction: this.showBackActions ? this.closeAction : undefined,
        onConnectionSuccess: this.handleConnectionSuccess,
        onSave: this.saveCredentialsMethod,
        asyncTimeoutReached: this.asyncTimeoutReached,
        showBackNavigation: true,
        onTimeout: () => {
          if (this.jobId) {
            this.openModal({
              component: AsyncFallbackPopup,
              popupAlignment: 'center',
            });
          } else {
            this.goToCashflow();
          }
        },
        loadingStartTime: this.loadingStartTime,
        preventOBkConnection: this.preventOBkConnection,
      };

      if (!this.sourceName) {
        propsToPass.backAction = this.backToSourceSelection;
      }
      return propsToPass;
    },
    sourceTypeString() {
      return (this.sourceType === 'bank') ? 'הבנק' : 'חברת האשראי';
    },
    backAction() {
      return this.allowBackToSourceSelection ? this.backToSourceTypeSelection : undefined;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('credentials', ['saveCredentials', 'fetchCredentials', 'updateCredentials', 'fetchLoadingCredentialsState', 'handleLoadingState']),
    ...mapMutations('credentials', ['setClosedBankingCredentialsIdToUpdate']),
    scrollToTop() {
      this.$refs.credentialsFlow.scrollIntoView();
    },
    async goToCashflow() {
      // There are some edge cases where the loading creds are stuck - if more than 1 hour1 we want to allow the user to move on
      const loadingToolLong = this.credentialsLoadingTooLong;
      Segment.trackUserGot('CredentialsTimeoutRedirectToHome', { loadingToolLong });
      this.$emit('timeout');
      if (!loadingToolLong) {
        this.closeModal();
        await router.push({ path: '/home' });
      }
    },
    async saveCredentialsMethod({ sourceName, credentials, name, provider }) {
      if (this.updateMode) {
        await this.updateCredentials({ source: this.sourceToUpdate, credentials, isSync: this.isSyncFlow, renew: true });
      } else {
        await this.saveCredentials({ sourceName, credentials, name, provider });
      }
    },
    async handleConnectionSuccess() {
      if (this.invalidCredentialsList.length === 0) {
        await this.fetchCredentials();
      }
    },
    validateName({ sourceName, name, isOpenBanking = false }) {
      const nameAlreadyUsedInOtherCredentials = _.some(this.credentialsList, currentCreds => {
        const openBankingCreds = !!currentCreds.openBankingConsentId;
        return currentCreds.sourceName === sourceName && currentCreds.name === name && !!openBankingCreds === isOpenBanking;
      });

      this.nameAlreadyInUseError = nameAlreadyUsedInOtherCredentials;
    },
    handleSourceTypeSelected(sourceType) {
      this.sourceType = sourceType;
      this.goToNextComponent();
    },
    async closeAction() {
      this.loading = true;
      this.sourceToConnect = null;
      this.componentName = null;
      this.$emit('close');
      this.loading = false;
    },
    goToNextComponent() {
      const currentComponentIndex = _.findIndex(this.componentsOrder, component => component === this.componentName);
      this.componentName = this.componentsOrder[currentComponentIndex + 1];
      this.scrollToTop();
    },
    async backToSourceTypeSelection() {
      this.componentName = this.componentNames.sourceTypeSelectionModal;
      this.sourceType = null;
    },
    async backToSourceSelection() {
      this.componentName = this.componentNames.sourceSelectionModal;
      this.sourceToConnect = null;
    },
    async setSelectedSource(source) {
      this.sourceToConnect = source.sourceName;
      this.goToNextComponent();
    },
    closeAfterSuccess() {
      this.$emit('success');
      this.closeAction();
    },
  },
  watch: {
    async socketMessage(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.fetchLoadingCredentialsState();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-credentials-flow {
  height: 100%;
}

</style>
