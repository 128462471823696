<template>
  <div class="connected-credentials-card">
    <snack-bar v-if="showStatus" :variant="headerVariant" class="header no-left-padding">
      <template v-slot:primary>
        <div class="header-text-container">
          <div class="bold">{{ headerText }}</div>
          <div v-if="displayHeaderTextDetails">{{ headerTextDetails }}</div>
        </div>
      </template>
      <template v-slot:secondary>
        <credentials-menu :source="source" />
      </template>
    </snack-bar>
    <open-banking-tag v-if="isOpenBanking"/>
    <div class="top-container">
      <source-logo :source-name="sourceName" :name="name" class="logo-container"/>
      <slot name="buttons" v-if="isAllOBKSourcesValid"></slot>
    </div>
    <div v-if="isObkFailed" class="obk-failure-message">
      <div v-if="isSourceDisabledObk" class="obk-failure-warning message">
        <img src="/images/info-icon-blue.svg" class="warning-icon">
        <div class="warning-text">
        <span class="bold">
          יש תקלה ב{{ source.indefiniteDisplayName }}.
        </span>
          אנחנו בקשר עם הצוות הטכני של {{ sourceName }} כדי להחזיר את השירות כמה שיותר מהר
        </div>
      </div>
      <div v-else>
        <riseup-button
                       :action="reconnectSource"
                       :loading="loading"
                       :title="'לחידוש ההסכמה'"
                       class="reconnect-button"/>
      </div>
    </div>
    <separator/>
    <expandable-connected-account-list
      :title="dropDownText"
      :disabled="disabled"
      :accounts="accounts"
      :source-type="sourceType"
      :always-expand="alwaysExpand"
    />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import SourceLogo from '@/base-components/onboarding/SourceLogo';
import ExpandableConnectedAccountList from '@/base-components/onboarding/ExpandableConnectedAccountList';
import Segment from '@/Segment';
import InvalidCredsConsts from '@/constants/invalid-creds';
import OpenBankingTag from '@/base-components/onboarding/openBanking/OpenBankingTag';

import { mapActions, mapGetters, mapState } from 'vuex';
import ConsentActionsMixin from '@/base-components/onboarding/openBanking/ConsentActionsMixin.vue';
import CredentialsMenu from '../credentials/credentials-list/CredentialsMenu';

export default {
  name: 'ConnectedCredentialsCard',
  components: {
    Separator: BaseUI.Separator,
    SnackBar: BaseUI.SnackBar,
    ExpandableConnectedAccountList,
    SourceLogo,
    CredentialsMenu,
    OpenBankingTag,
    RiseupButton: BaseUI.RiseupButton,
  },
  mixins: [ConsentActionsMixin],
  data() {
    return {
      loading: false,
    };
  },
  props: {
    name: { type: String, required: true },
    sourceName: { type: String, required: true },
    sourceType: { type: String, required: true },
    status: { type: String },
    accounts: { type: Array },
    source: { type: Object, required: false },
    enableCredentialsUpdate: { type: Boolean, required: false, default: false },
    showStatus: { type: Boolean, default: true },
    alwaysExpand: { type: Boolean, default: false },
  },
  created() {
    this.initApplicationState();
    if (this.status === 'passwordInvalid') {
      Segment.trackUserGot('CredentialsCardShownInvalid', {
        reason: this.source?.statusExtraData?.reason,
        sourceName: this.sourceName,
      });
    }
  },
  computed: {
    ...mapState('applicationState', ['notificationState']),
    ...mapGetters('applicationState', ['notificationTypes']),
    ...mapGetters('credentials', ['getDisabledOBKSources', 'isAllOBKSourcesValid']),
    isOpenBanking() {
      return this.source && !!this.source.openBankingConsentId;
    },
    disabled() {
      return !this.isConnected;
    },
    headerVariant() {
      if (this.status === 'partiallyAuthorized') {
        return 'incomplete';
      }
      if (!this.isConnected) {
        return 'danger';
      }
      return 'success';
    },
    headerText() {
      if (this.status === 'partiallyAuthorized') {
        return 'ממתין לאישור שותפ.ה בבנק';
      }

      return ` חשבון ${this.connectedOrDisconnectedText}`;
    },
    invalidStatusReason() {
      return this.source?.statusExtraData?.reason;
    },
    displayHeaderTextDetails() {
      return (this.status === 'passwordInvalid') || this.isObkFailed;
    },
    headerTextDetails() {
      if (this.isObkFailed) {
        return ` יש תקלה ב${this.source.indefiniteDisplayName}`;
      }
      if (this.source && this.source.openBankingConsentId && this.status === 'passwordInvalid') {
        return 'ההסכמה לא בתוקף';
      }
      return InvalidCredsConsts.ConnectedCredentialsCardInvalid[this.invalidStatusReason] || '';
    },
    connectedOrDisconnectedText() {
      return this.isConnected ? 'מחובר' : 'לא מחובר.';
    },
    isConnected() {
      return !['passwordInvalid', 'obkSourceFailure'].includes(this.status);
    },
    dropDownText() {
      return this.sourceType === 'card' ? 'כרטיסים כלולים בתזרים' : 'חשבונות כלולים בתזרים';
    },
    isObkFailed() {
      return this.status === 'obkSourceFailure';
    },
    isSourceDisabledObk() {
      return this.getDisabledOBKSources.includes(this.source.id);
    },
  },
  methods: {
    ...mapActions('applicationState', ['initApplicationState']),
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('credentials', ['deleteCredentials']),
    async reconnectSource() {
      Segment.trackUserInteraction('RenewConsentFromCardClicked', { source: this.sourceName });
      this.loading = true;
      window.location.href = await this.updateConsent({
        consentId: this.source.openBankingConsentId,
        bankIdentifier: this.source.openBanking.aggregatorBankId,
        credentialsId: this.source.credentialsId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../scss/mixins';
.connected-credentials-card {
  position: relative;
  width: 100%;
  border: solid 1px $riseup_gray_0;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(148, 148, 148, 0.4);
  background-color: $riseup_white;

  .header {
    @include responsive-padding;
    display: flex;
    align-items: center;
    height: 40px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding-left: 15px;
    font-size: 14px;
    &.no-left-padding {
      padding-left: 0;
    }

    .header-text-container {
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }

    .bold {
      font-weight: bold;
      margin-left: 4px
    }

    @media (min-width: 430px) {
      padding-left: 15px;
    }

    .toggle-menu {
      position: relative;
      padding: 20px;
      right: 20px;
      cursor: pointer;
    }
  }

  .obk-failure-message {
    margin: 0 24px 24px 24px;
    line-height: 18px;
    border-radius: 8px;
  }

  .top-container {
    @include responsive-padding-top-bottom;
    @include responsive-padding;

    .button-container {
      .connect-credit-card-button {
        width: 100%;
      }
    }
  }

  .reconnect-button {
    margin-top: 10px;
    width: 100%;
    height: 50px;

    ::v-deep .button-titles .button-title {
      font-size: 16px;
    }
  }

  .action {
    display: flex;
    align-items: center;
    font-family: SimplerPro;
    font-size: 14px;
    padding: 0 8px;
    cursor: pointer;
    width: calc(100% - 48px);
    margin: 24px;
    height: 50px;
  }
}

.obk-failure-warning {
  background-color: rgba($riseup_light_blue, 0.5);
}

.warning-text {
  font-size: 14px;
  padding: 17px 0 17px 17px;
  line-height: 20px;
  font-weight: 400;
}

.warning-icon {
  padding: 19px;
  height: 100%;
}

.renew-consent-message {
  background-color: rgba($riseup_light_blue, 0.5);
}

.message {
  line-height: 18px;
  border-radius: 8px;
  display: flex;
}

.logo-container {
  width: 100%;
  height: 100%;
  object-fit: contain;

  .logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
