import _ from 'lodash';

// noinspection LongLine
const sourcesConfiguration = {
  poalim: {
    id: 'poalim',
    sourceName: 'הפועלים',
    displayName: 'הפועלים',
    backendName: 'poalim',
    sortPriority: 10,
    bankCode: 12,
    openBanking: {
      sourceName: 'הפועלים בנקאות פתוחה',
      aggregatorBankId: 'poalim',
      jointAccountSupported: true,
      allowDesktop: true,
      title: 'באתר או באפליקציית פועלים',
      buttonTitle: 'לחיבור חשבון מהיר בפועלים',
      redirectPopup: {
        title: 'רגע לפני שנעבור לפועלים:',
        buttonTitle: 'להמשך החיבור בפועלים',
        backText: true,
      },
    },
    credentials: {
      fields: {
        username: {
          key: 'username',
          name: 'קוד משתמש',
          type: 'text',
          validation: 'min-length:6|not-he',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'min-length:8|not-he',
        },
      },
    },
    links: {
      homepage: 'https://www.bankhapoalim.co.il',
      login: 'https://login.bankhapoalim.co.il/cgi-bin/poalwwwc?reqName=getLogonPage',
      signup: 'https://join.bankhapoalim.co.il/reg/Np/main.jsp',
    },
    logoFile: 'hapoalim-large.svg',
    logoFileStacked: 'hapoalim-small.svg',
    type: 'bank',
  },
  leumi: {
    id: 'leumi',
    sourceName: 'לאומי',
    displayName: 'לאומי',
    backendName: 'leumiBank',
    sortPriority: 10,
    bankCode: 10,
    openBanking: {
      sourceName: 'לאומי בנקאות פתוחה',
      aggregatorBankId: 'leumi',
      jointAccountSupported: true,
      allowDesktop: true,
      title: 'באתר או באפליקציית לאומי',
      buttonTitle: 'לחיבור חשבון מהיר בלאומי',
      redirectPopup: {
        title: ' רגע לפני שנעבור ללאומי:',
        buttonTitle: 'להמשך החיבור בלאומי',
        backText: false,
      },
    },
    credentials: {
      sourceName: 'לאומי',
      fields: {
        username: {
          key: 'username',
          name: 'זיהוי משתמש',
          type: 'text',
          validation: 'exact-length:7|not-he|not-special',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'min-length:6|not-he|not-special',
        },
      },
    },
    links: {
      homepage: 'https://www.leumi.co.il',
      login: 'https://hb2.bankleumi.co.il/uniquesig4e0824291ffbe1b42058d6558ed87217/uniquesig0/InternalSite/CustomUpdate/eBank_ULI_Login.asp?resource_id=C66B095BD60649D18ECB79F04C657517&login_type=2&site_name=leumi&secure=1&URLHASH=14b6a749-0795-469f-b880-0752d25aab16&orig_url=https%3a%2f%2fhb2.bankleumi.co.il%2fH%2fLogin.html%3fblga%3d457807548.1539249230', // eslint-disable-line
    },
    logoFile: 'leumi-large.svg',
    logoFileStacked: 'leumi-small.svg',
    type: 'bank',
  },
  pepper: {
    id: 'pepper',
    sourceName: 'פפר',
    backendName: 'pepperBank',
    displayName: 'פפר',
    sortPriority: 100,
    bankCode: 10,
    openBanking: {
      sourceName: 'פפר בנקאות פתוחה',
      aggregatorBankId: 'pepper',
      allowDesktop: false,
      buttonTitle: 'לחיבור חשבון מהיר בפפר',
      title: 'באפליקציית פפר:',
      redirectPopup: {
        title: 'רגע לפני שנעבור לפפר:',
        buttonTitle: 'להמשך החיבור בפפר',
        backText: true,
      },
    },
    links: {
      homepage: 'https://lp.pepper.co.il/',
    },
    logoFile: 'pepper-large.svg',
    logoFileStacked: 'pepper-large.svg',
    type: 'bank',
  },
  budgetz: {
    id: 'budgetz',
    sourceName: 'budgetz',
    backendName: 'budgetz',
    displayName: 'Budgetz',
    bankCode: 999,
    openBanking: {
      sourceName: 'budgetz',
      aggregatorBankId: 'budgetz',
      allowDesktop: true,
      buttonTitle: 'לחיבור חשבון מהיר Budgetz',
      title: ' באפליקציית Budgetz',
      redirectPopup: {
        title: 'רגע לפני שנעבור ל-Budgetz:',
        buttonTitle: 'להמשך החיבור ב-Budgetz',
        backText: true,
      },
    },
    links: {
      homepage: 'https://api.budgetz.co.il/',
    },
    logoFile: 'budgetz-long.svg',
    logoFileStacked: 'budgetz-long.svg',
    type: 'cash',
  },
  mercantile: {
    id: 'mercantile',
    sourceName: 'מרכנתיל',
    displayName: 'מרכנתיל',
    backendName: 'MercantileBank',
    sortPriority: 50,
    bankCode: 17,
    credentials: {
      fields: {
        idNumber: {
          key: 'idNumber',
          name: 'מספר זהות',
          type: 'number',
          validation: 'id',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'min-length:6|not-he',
        },
        identityCode: {
          key: 'identityCode',
          name: 'קוד מזהה',
          type: 'text',
          validation: 'not-he|not-special',
        },
      },
    },
    openBanking: {
      sourceName: 'מרכנתיל בנקאות פתוחה',
      aggregatorBankId: 'mercantile',
      jointAccountSupported: true,
      allowDesktop: true,
      title: 'באתר או באפליקציית מרכנתיל',
      buttonTitle: 'לחיבור חשבון מהיר במרכנתיל',
      redirectPopup: {
        title: 'שתי נקודות חשובות לפני שנעבור לאתר מרכנתיל',
        buttonTitle: 'הבנתי, אפשר להמשיך',
        backText: false,
      },
    },
    links: {
      homepage: 'https://www.mercantile.co.il',
      login: 'https://www.mercantile.co.il/MB/private/',
      signup: 'https://www.mercantile.co.il/MB/private/join-internet',
    },
    logoFile: 'mercantile-large.png',
    logoFileStacked: 'mercantile-small@3x.jpg',
    type: 'bank',
  },
  discountNew: {
    id: 'discount',
    sourceName: 'דיסקונט',
    displayName: 'דיסקונט',
    backendName: 'DiscountBank',
    sortPriority: 1,
    bankCode: 11,
    openBanking: {
      sourceName: 'דיסקונט בנקאות פתוחה',
      aggregatorBankId: 'discount',
      jointAccountSupported: true,
      allowDesktop: true,
      title: 'באתר או באפליקציית דיסקונט',
      buttonTitle: 'לחיבור חשבון מהיר בדיסקונט',
      redirectPopup: {
        title: 'רגע לפני שנעבור לדיסקונט:',
        buttonTitle: 'הבנתי, אפשר להמשיך',
        backText: false,
      },
    },
    credentials: {
      fields: {
        idNumber: {
          key: 'idNumber',
          name: 'מספר זהות',
          type: 'number',
          validation: 'id',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'min-length:6|not-he',
        },
        identityCode: {
          key: 'identityCode',
          name: 'קוד מזהה',
          type: 'text',
          validation: 'not-he|not-special',
        },
      },
    },
    links: {
      homepage: 'https://www.discountbank.co.il',
      login: 'https://www.discountbank.co.il/DB/private',
      signup: 'https://www.discountbank.co.il/DB/private/join',
    },
    logoFile: 'discount-large.svg',
    logoFileStacked: 'discount-small.svg',
    instructions: 'Discount.vue',
    type: 'bank',
  },
  mizrahi: {
    sourceName: 'מזרחי',
    id: 'mizrahi',
    displayName: 'מזרחי',
    backendName: 'mizrahi',
    sortPriority: 20,
    bankCode: 20,
    openBanking: {
      sourceName: 'מזרחי בנקאות פתוחה',
      aggregatorBankId: 'mizrahi',
      jointAccountSupported: true,
      allowDesktop: true,
      title: 'באתר או באפליקציית מזרחי',
      buttonTitle: 'לחיבור חשבון מהיר במזרחי',
      redirectPopup: {
        title: ' רגע לפני שנעבור למזרחי:',
        buttonTitle: 'להמשך החיבור במזרחי',
        backText: false,
      },
    },
    credentials: {
      fields: {
        username: {
          key: 'username',
          name: 'שם משתמש',
          type: 'text',
          validation: 'not-he|not-special',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'min-length:6|not-he',
        },
      },
    },
    links: {
      homepage: 'https://www.mizrahi-tefahot.co.il',
      login: 'https://www.mizrahi-tefahot.co.il/login/LoginMTO.aspx?errorcode=198&TYPE=100663297&REALMOID=06-379e9a46-0ea8-4ab6-8ab1-880e6888203e&GUID=&SMAUTHREASON=0&METHOD=GET&SMAGENTNAME=$SM$e7C0GBhQlQIsjzKU1bTHYzA0SjkxVktpnumjMG0JJY5aUBhHzLoziO%2fUD5Ind0FI&TARGET=$SM$HTTP%3a%2f%2fmto%2emizrahi-tefahot%2eco%2eil%2fOnline%2fDefault%2easpx%3flanguage%3dhe-IL%26page%3d~%2fIC%2fP946%2easpx', // eslint-disable-line
    },
    logoFile: 'mizrachi-large.svg',
    logoFileStacked: 'mizrachi-small.svg',
    type: 'bank',
  },
  yahav: {
    id: 'yahav',
    sourceName: 'יהב',
    displayName: 'יהב',
    backendName: 'YahavBank',
    waitingListName: 'Yahav',
    sortPriority: 100,
    bankCode: 4,
    openBanking: {
      sourceName: 'יהב בנקאות פתוחה',
      aggregatorBankId: 'yahav',
      allowDesktop: true,
      buttonTitle: 'לחיבור חשבון מהיר ביהב',
      title: 'באתר או באפליקציית יהב',
      redirectPopup: {
        title: 'נקודה חשובה לפני שנעבור ליהב:',
        buttonTitle: 'להמשך החיבור ביהב',
        backText: true,
      },
    },
    credentials: {
      fields: {
        userName: {
          key: 'userName',
          name: 'שם משתמש',
          type: 'text',
          validation: 'not-he',
        },
        idNumber: {
          key: 'idNumber',
          name: 'תעודת זהות (9 ספרות)',
          type: 'number',
          validation: 'id',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'not-he',
        },
      },
    },
    links: {
      homepage: 'https://www.bank-yahav.co.il',
      login: 'https://www.bank-yahav.co.il/Entrance.aspx',
      signup: 'https://register.yahav.co.il/IBAdmin-SelfRegistration/registrationINIT.action?hiddenstate=Self_Registration',
    },
    logoFile: 'yahav-large.jpg',
    logoFileStacked: 'yahav-large.jpg',
    type: 'bank',
  },
  massad: {
    id: 'massad',
    sourceName: 'מסד',
    displayName: 'מסד',
    backendName: 'massad',
    sortPriority: 50,
    bankCode: 46,
    openBanking: {
      sourceName: 'מסד בנקאות פתוחה',
      aggregatorBankId: 'massad',
      jointAccountSupported: true,
      allowDesktop: true,
      title: 'באתר או באפליקציית מסד',
      buttonTitle: 'לחיבור חשבון מהיר במסד',
      redirectPopup: {
        title: ' רגע לפני שנעבור למסד:',
        buttonTitle: 'להמשך החיבור במסד',
        backText: false,
      },
    },
    credentials: {
      fields: {
        username: {
          key: 'username',
          name: 'קוד משתמש',
          type: 'text',
          validation: 'min-length:4|not-he|not-special',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'min-length:6|not-he',
        },
      },
    },
    links: {
      homepage: 'https://www.bankmassad.co.il',
      login: 'https://online.bankmassad.co.il/wps/portal/',
    },
    logoFile: 'massad-large.svg',
    logoFileStacked: 'massad-small.svg',
    type: 'bank',
  },
  pagi: {
    id: 'pagi',
    sourceName: 'פאגי',
    displayName: 'פאגי',
    backendName: 'pagi',
    sortPriority: 50,
    bankCode: 52,
    openBanking: {
      sourceName: 'פאגי בנקאות פתוחה',
      aggregatorBankId: 'pagi',
      jointAccountSupported: true,
      allowDesktop: true,
      title: 'באתר או באפליקציית פאגי',
      buttonTitle: 'לחיבור חשבון מהיר בפאגי',
      redirectPopup: {
        title: ' רגע לפני שנעבור לפאגי:',
        buttonTitle: 'להמשך החיבור בפאגי',
        backText: false,
      },
    },
    credentials: {
      fields: {
        username: {
          key: 'username',
          name: 'קוד משתמש',
          type: 'text',
          validation: 'min-length:4|not-he|not-special',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'min-length:6|not-he',
        },
      },
    },
    links: {
      homepage: 'https://www.pagi.co.il',
      login: 'https://www.pagi.co.il/wps/portal/',
    },
    logoFile: 'pagi-large.png',
    logoFileStacked: 'pagi-large.png',
    type: 'bank',
  },
  benleumi: {
    id: 'benleumi',
    sourceName: 'הבינלאומי',
    displayName: 'הבינלאומי',
    backendName: 'benleumi',
    sortPriority: 30,
    bankCode: 31,
    openBanking: {
      sourceName: 'הבינלאומי בנקאות פתוחה',
      aggregatorBankId: 'benleumi',
      jointAccountSupported: true,
      allowDesktop: true,
      title: 'באתר או באפליקציית הבינלאומי',
      buttonTitle: 'לחיבור חשבון מהיר בבינלאומי',
      redirectPopup: {
        title: ' רגע לפני שנעבור לבינלאומי:',
        buttonTitle: 'להמשך החיבור בבינלאומי',
        backText: false,
      },
    },
    credentials: {
      fields: {
        username: {
          key: 'username',
          name: 'קוד משתמש',
          type: 'text',
          validation: 'min-length:4|not-he|not-special',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'min-length:6|not-he',
        },
      },
    },
    links: {
      homepage: 'https://www.fibi.co.il',
      login: 'https://online.fibi.co.il/wps/portal/',
    },
    logoFile: 'habeinleumi-large.svg',
    logoFileStacked: 'habeinleumi-small.svg',
    type: 'bank',
  },
  otsar: {
    id: 'otsar',
    sourceName: 'אוצר החייל',
    displayName: 'אוצר החייל',
    backendName: 'otsar',
    sortPriority: 50,
    bankCode: 14,
    openBanking: {
      sourceName: 'אוצר החייל בנקאות פתוחה',
      aggregatorBankId: 'otsar',
      jointAccountSupported: true,
      allowDesktop: true,
      title: 'באתר או באפליקציית אוצר החייל',
      buttonTitle: 'לחיבור חשבון מהיר באוצר החייל',
      redirectPopup: {
        title: ' רגע לפני שנעבור לאוצר החייל:',
        buttonTitle: 'להמשך החיבור באוצר החייל',
        backText: false,
      },
    },
    credentials: {
      fields: {
        username: {
          key: 'username',
          name: 'קוד משתמש',
          type: 'text',
          validation: 'min-length:4|not-he|not-special',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'min-length:6|not-he',
        },
      },
    },
    links: {
      homepage: 'https://www.bankotsar.co.il',
      login: 'https://online.bankotsar.co.il/wps/portal/',
    },
    logoFile: 'otsar-large.png',
    logoFileStacked: 'otsar-large.png',
    type: 'bank',
  },
  ubank: {
    id: 'ubank',
    sourceName: 'יובנק',
    displayName: 'יובנק',
    backendName: 'ubank',
    sortPriority: 50,
    bankCode: 26,
    openBanking: {
      sourceName: 'יובנק בנקאות פתוחה',
      aggregatorBankId: 'ubank',
      jointAccountSupported: true,
      allowDesktop: true,
      title: 'באתר או באפליקציית יובנק',
      buttonTitle: 'לחיבור חשבון מהיר ביובנק',
      redirectPopup: {
        title: ' רגע לפני שנעבור ליובנק:',
        buttonTitle: 'להמשך החיבור ביובנק',
        backText: false,
      },
    },
    credentials: {
      fields: {
        username: {
          key: 'username',
          name: 'קוד משתמש',
          type: 'text',
          validation: 'min-length:4|not-he|not-special',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'min-length:6|not-he',
        },
      },
    },
    links: {
      homepage: 'https://www.fibi.co.il/wps/portal/FibiMenu/Marketing/Platinum',
      login: 'https://www.fibi.co.il/wps/portal/FibiMenu/Marketing/Platinum',
    },
    logoFile: 'ubank-large.png',
    logoFileStacked: 'ubank-large.png',
    type: 'bank',
  },
  egud: {
    id: 'egud',
    sourceName: 'אגוד',
    displayName: 'אגוד',
    backendName: 'egudbank',
    sortPriority: 30,
    bankCode: 13,
    credentials: {
      fields: {
        username: {
          key: 'username',
          name: 'זיהוי משתמש',
          type: 'text',
          validation: 'exact-length:7|not-he',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'min-length:6|not-he',
        },
      },
    },
    links: {
      homepage: 'https://www.unionbank.co.il/igud/homepage/',
      login: 'https://hb.unionbank.co.il/H/Login.html',
    },
    logoFile: 'igud-large.svg',
    logoFileStacked: 'igud-small.svg',
    type: 'bank',
    hidden: true,
  },
  doar: {
    id: 'doar',
    sourceName: 'הדואר',
    displayName: 'בנק הדואר',
    backendName: 'doar',
    sortPriority: 50,
    bankCode: 9,
    credentials: {
      fields: {
        username: {
          key: 'username',
          name: 'שם משתמש',
          type: 'text',
          validation: 'not-he',
        },
        idNumber: {
          key: 'idNumber',
          name: 'מספר זהות',
          type: 'number',
          validation: 'id',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'not-he',
        },
      },
    },
    links: {
      homepage: 'https://www.bankhadoar.co.il',
      login: 'https://www.bankhadoar.co.il/#/Login',
      signup: 'https://www.bankhadoar.co.il/#/Login/Register',
    },
    logoFile: 'doar-large.svg',
    logoFileStacked: 'doar-small.svg',
    type: 'bank',
  },
  jerusalem: {
    sourceName: 'ירושלים',
    displayName: 'בנק ירושלים',
    waitingListName: 'Jerusalem Bank',
    sortPriority: 100,
    bankCode: 54,
    links: {
      homepage: 'https://www.bankjerusalem.co.il/',
    },
    logoFile: 'jerusalem-large.jpg',
    logoFileStacked: 'jerusalem-large.jpg',
    type: 'bank',
    unsupported: true,
  },
  oneZero: {
    sourceName: 'one-zero',
    displayName: 'ONE ZERO',
    waitingListName: 'One Zero Bank',
    sortPriority: 100,
    bankCode: 18,
    links: {
      homepage: 'https://www.onezerobank.com/',
    },
    logoFile: 'one-zero.png',
    logoFileStacked: 'one-zero.png',
    type: 'bank',
    unsupported: true,
  },
  isracard: {
    id: 'isracard',
    sourceName: 'ישראכארט',
    displayName: 'ישראכרט',
    backendName: 'isracard',
    openBanking: {
      sourceName: 'ישראכארט בנקאות פתוחה',
      aggregatorBankId: 'isracard',
      allowDesktop: true,
      buttonTitle: 'לחיבור חשבון מהיר בישראכרט',
      title: 'באתר או באפליקציית ישראכרט',
      redirectPopup: {
        title: 'רגע לפני שנעבור לישראכרט:',
        buttonTitle: 'להמשך החיבור בישראכרט',
        backText: true,
      },
    },
    credentials: {
      fields: {
        idNumber: {
          key: 'idNumber',
          name: 'מספר תעודת זהות',
          type: 'number',
          validation: 'id',
        },
        last6digits: {
          key: 'last6digits',
          name: '6 ספרות אחרונות',
          type: 'number',
          validation: 'exact-length:6|only-digits',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'min-length:8',
        },
      },
    },
    links: {
      homepage: 'https://www.isracard.co.il',
      login: 'https://digital.isracard.co.il/personalarea/Login/',
      signup: 'https://digital.isracard.co.il/personalarea/loginarea/#/Register',
      forgotPassword: 'https://digital.isracard.co.il/personalarea/loginarea/#/ForgotPassword',
    },
    logoFile: 'isracard-logo.png',
    instructions: 'Isracard.vue',
    logoFileStacked: 'isracard-stacked.svg',
    cardLogo: 'isracard.png',
    type: 'card',
  },
  cal: {
    id: 'cal',
    sourceName: 'ויזה כאל',
    displayName: 'כאל',
    backendName: 'cal',
    openBanking: {
      sourceName: 'כאל בנקאות פתוחה',
      aggregatorBankId: 'cal',
      allowDesktop: true,
      buttonTitle: 'לחיבור חשבון מהיר בכאל',
      title: 'באתר או באפליקציית כאל',
      redirectPopup: {
        title: 'רגע לפני שנעבור לכאל',
        buttonTitle: 'להמשך החיבור בכאל',
        backText: true,
      },
    },
    credentials: {
      fields: {
        username: {
          key: 'username',
          name: 'שם משתמש',
          type: 'text',
          validation: 'exact-length:8|not-he',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'min-length:8|max-length:10|not-he|not-special',
        },
      },
    },
    links: {
      homepage: 'https://www.cal-online.co.il',
      login: 'https://services.cal-online.co.il/Card-Holders/SCREENS/AccountManagement/Login.aspx?csrt=17535042919882254315&ReturnUrl=%2fCard-Holders%2fSCREENS%2fAccountManagement%2fcalposting.aspx%3futm_source%3dcm%26utm_medium%3dsms%26utm_campaign%3ddperut', // eslint-disable-line
      signup: 'https://services.cal-online.co.il/Card-Holders/SCREENS/AccountManagement/Joining.aspx',
      forgotPassword: 'https://services.cal-online.co.il/card-holders/Screens/AccountManagement/PasswordRestoring.aspx',
    },
    logoFile: 'cal-diners.svg',
    instructions: 'Cal.vue',
    logoFileStacked: 'cal-diners-stacked.svg',
    cardLogo: 'diners_visacal.png',
    type: 'card',
  },
  leumicard: {
    id: 'leumicard',
    sourceName: 'לאומי קארד',
    displayName: 'מקס',
    backendName: 'leumicard',
    openBanking: {
      sourceName: 'מקס בנקאות פתוחה',
      aggregatorBankId: 'leumicard',
      allowDesktop: true,
      buttonTitle: 'לחיבור חשבון מהיר במקס',
      title: 'באתר או באפליקציית מקס',
      redirectPopup: {
        title: ' רגע לפני שנעבור למקס:',
        buttonTitle: 'להמשך החיבור במקס',
        backText: true,
      },
    },
    credentials: {
      fields: {
        username: {
          key: 'username',
          name: 'שם משתמש',
          type: 'text',
          validation: 'not-he',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'min-length:8|not-he',
        },
      },
    },
    links: {
      homepage: 'https://www.max.co.il',
      login: 'https://www.max.co.il/homepage/welcome',
      signup: 'https://online.max.co.il/Anonymous/Registration.aspx?SourceGA=PasswordLogin',
      forgotPassword: 'https://online.max.co.il/Anonymous/RecoverPassword.aspx?SourceGA=PasswordLogin',
    },
    logoFile: 'leumicard-max.png',
    instructions: 'Leumicard.vue',
    logoFileStacked: 'max-leumi-stacked.svg',
    cardLogo: 'leumicard.png',
    type: 'card',
  },
  amex: {
    id: 'amex',
    sourceName: 'אמריקן אקספרס',
    displayName: 'אמריקן אקספרס',
    backendName: 'americanexpress',
    openBanking: {
      sourceName: 'אמריקן אקספרס בנקאות פתוחה',
      aggregatorBankId: 'americanexpress',
      allowDesktop: true,
      buttonTitle: 'לחיבור חשבון מהיר באמריקן אקספרס',
      title: 'באתר או באפליקציית אמריקן אקספרס',
      redirectPopup: {
        title: 'רגע לפני שנעבור לאמריקן אקספרס:',
        buttonTitle: 'להמשך החיבור באמריקן אקספרס',
        backText: true,
      },
    },
    credentials: {
      fields: {
        idNumber: {
          key: 'idNumber',
          name: 'מספר תעודת זהות',
          type: 'number',
          validation: 'id',
        },
        last6digits: {
          key: 'last6digits',
          name: '6 ספרות אחרונות',
          type: 'number',
          validation: 'exact-length:6|only-digits',
        },
        password: {
          key: 'password',
          name: 'סיסמה קבועה',
          type: 'password',
          renew: true,
          validation: 'min-length:8',
        },
      },
    },
    links: {
      homepage: 'https://www.americanexpress.co.il',
      login: 'https://he.americanexpress.co.il/personalarea/login/',
      signup: 'https://he.americanexpress.co.il/personalarea/loginarea/#/Register',
      forgotPassword: 'https://he.americanexpress.co.il/personalarea/loginarea/#/ForgotPassword',
    },
    logoFile: 'amex-large.svg',
    instructions: 'Amex.vue',
    logoFileStacked: 'amex-stacked.svg',
    cardLogo: 'amex.png',
    type: 'card',
  },
  meitav: {
    id: 'meitav',
    sourceName: 'מיטב',
    displayName: 'מיטב',
    backendName: 'meitav',
    sortPriority: 1,
    openBanking: {
      sourceName: 'מיטב הלוואות',
      aggregatorBankId: 'meitav',
      jointAccountSupported: false,
      allowDesktop: true,
      title: 'באתר או באפליקציית מיטב',
      buttonTitle: 'לחיבור חשבון מהיר במיטב',
      redirectPopup: {
        title: ' רגע לפני שנעבור למיטב:',
        buttonTitle: 'להמשך החיבור במיטב',
        backText: false,
      },
    },
    links: {
      homepage: 'https://www.meitav.co.il',
    },
    logoFile: 'meitav.svg',
    logoFileStacked: 'meitav.svg',
    type: 'bank',
  },
};

function _toIndefiniteDisplayName(displayName) {
  return displayName.startsWith('ה') ? displayName.substring(1) : displayName;
}

_.chain(sourcesConfiguration).map(source => _.extend(source, { indefiniteDisplayName: _toIndefiniteDisplayName(source.displayName) })).value();

export function getSourceConfigurationBySourceName(sourceName) {
  if (_.isEmpty(sourceName)) {
    return undefined;
  }
  return _.find(sourcesConfiguration, source => source.sourceName === sourceName
      || (source.openBanking && source.openBanking.sourceName === sourceName)
      || (source.id && source.id === sourceName)
      || source.backendName === sourceName);
}

export function sortedCardSources() {
  const cardArray = _.filter(sourcesConfiguration, source => source.type === 'card');
  return _.sortBy(cardArray, b => b.sortPriority);
}

export function getSourceConfigurationByID(id) {
  return _.find(sourcesConfiguration, { id });
}

export function getSourceConfigurationByAggregatorID(aggregatorBankId) {
  return _.find(sourcesConfiguration, source => source.openBanking && source.openBanking.aggregatorBankId === aggregatorBankId);
}

export function getBanks() {
  return _.filter(sourcesConfiguration, source => source.type === 'bank' && !source.hidden);
}

export function sortedCashSources() {
  const cardArray = _.filter(sourcesConfiguration, source => source.type === 'cash');
  return _.sortBy(cardArray, b => b.sortPriority);
}
