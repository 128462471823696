<template>
  <div class="card-header" :class="transactionCategoryClass">
    <div v-if="isSavingTransaction">
      <span class="name">צפי הפקדה קבועה לחיסכון</span>
    </div>
    <div v-else>
      <span class="name">צפי {{transactionType}} קבועה</span>
      • <span class="category">{{ expense }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardTransactionHeader',
  props: {
    isSavingTransaction: {
      type: Boolean,
      required: true,
    },
    expense: {
      type: String,
      required: true,
    },
    transactionCategoryClass: {
      type: String,
      required: true,
    },
    transactionType: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/category-color-mixins';

.card-header {
  @include category-color;
}
</style>
