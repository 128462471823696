<template>
  <div class="flex-column padding-ml">
    <div class="flex-column align-center justify-content-center">
      <img class="margin-top-xxl margin-bottom-ml" :style="{height: '66px'}"  :src="require('@/assets/icons/success-icon.svg')"/>
      <div class="ri-large-headline margin-bottom-ml">זהו, הדו״ח שלך מוכן!</div>
    </div>
    <div class="flex-column align-center padding-ml" :style="{borderRadius: '16px', backgroundColor: '#E1FBE0'}">
      <div class="margin-bottom-s ri-font-weight-bold">
      <span>  דו״ח הבנקאות הפתוחה של</span>
        <span>{{currentMonth}}</span>
      </div>
      <div class="margin-bottom-ml flex align-center padding-horizontal-m" style="text-align: center">
        יש להזין את מספר תעודת הזהות שלך בפתיחת הדו״ח
      </div>
      <riseup-button
        title="להוריד את הדו״ח"
        :action="downloadFile"
        :icon-props="{iconName: 'download', size: '24px'}"
        :bold="true"/>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import cashflowHistory from '@/store/utilities/cashflow-history';
import moment from 'moment';
import Segment from '@/Segment';
import AgamApi from '@/api/AgamApi';

export default {
  name: 'AgamResults',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {},
  data() {
    return {
    };
  },
  created() {
    Segment.trackUserGot('AgamResults_Entered');
  },
  computed: {
    currentMonth() {
      const monthName = cashflowHistory.getFullMonthName(moment());
      const year = moment().year();
      return `${monthName} ${year}`;
    },
  },
  methods: {
    async downloadFile() {
      Segment.trackUserGot('DownloadedAgamReport_Clicked');
      await AgamApi.downloadAgamReport();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
