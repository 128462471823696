<template>
  <div class="credit-card-v2">
      <img class="back-triangle" :src="require('@/assets/subscription/credit-card-header.svg')" alt="shapes image"/>
      <div class="margin-ml">
      <div class="ri-large-headline margin-horizontal-xs margin-bottom-ml">{{ mainTitleText }}</div>
        <benefit-notification-box-v2 v-if="benefitId" :flavor="flavors.creditCard" />
      </div>

    <separator />

    <div class="margin-horizontal-ml">
      <div class="margin-vertical-l ri-bold-title">על שם מי הכרטיס?</div>
      <personal-info-form/>
    </div>

    <separator />

    <div class="credit-card-details margin-horizontal-ml">
      <credit-card-details-form @init-failed="creditCardFormInitFailed"/>
    </div>

    <separator />

    <div class="payment-details ri-bold-title margin-vertical-l margin-horizontal-ml">
      <div class="margin-bottom-l">פרטי התשלום</div>
      <payment-details :price="price" :payment-date="formattedFirstBillingDate"
          :discount="!!discountPercentageAmount" :discount-text="`הנחה של ${discountPercentageAmount}%`" />
    </div>

    <subscription-terms-v2 />

    <div class="margin-ml">
      <div class="margin-bottom-m" v-if="!tokenizationError">
        <riseup-button :title="paymentTitle" :size="'small'" :action="onPaymentClicked" :loading="loadingToken"
                       :disabled="loadingToken || !validPersonalInfo || subscriptionButtonClicked"/>
      </div>
      <tokenize-error-v2 v-else />
      <div class="link ri-large-bold-body" @click="backToCashflow">ביטול</div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapState, mapGetters, mapActions } from 'vuex';

import router from '@/router';
import PersonalInfoForm from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/components/PersonalInfoForm';
import PaymentHelper from '@/pages/responsive-pages/authenticated/payment/PaymentHelper';
import MetricsApi from '@/api/MetricsApi';
import { SUBSCRIPTION_TYPES } from '@/constants/subscription-state';
import PaymentDetails from '@/pages/responsive-pages/authenticated/subscription/PaymentDetails.vue';
import BenefitNotificationBoxV2
  from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitNotificationBox2.vue';
import BenefitMixin from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitMixin.vue';
import SubscriptionTermsV2 from '@/pages/responsive-pages/authenticated/subscription/V2/SubscriptionTermsV2.vue';
import money from '@/utils/money';
import TokenizeErrorV2 from '@/pages/responsive-pages/authenticated/subscription/V2/TokenizeErrorV2.vue';
import Segment from '../../../../../Segment';
import CreditCardDetailsForm from '../../payment/CreditCardDetailsForm.vue';
import PaymeInterface from '../../../../../Payme';

export default {
  name: 'CreditCardV2',
  components: {
    TokenizeErrorV2,
    SubscriptionTermsV2,
    BenefitNotificationBoxV2,
    PaymentDetails,
    CreditCardDetailsForm,
    RiseupButton: BaseUI.RiseupButton,
    PersonalInfoForm,
    Separator: BaseUI.Separator,
  },
  mixins: [PaymentHelper, BenefitMixin],
  async created() {
    Segment.trackUserGot('SubscriptionCreditCardEntered', { subscriptionVersion: 'v2' });
  },
  beforeDestroy() {
    PaymeInterface.teardown();
  },
  data() {
    return {
      subscriptionButtonClicked: false,
    };
  },
  computed: {
    ...mapState('subscription', ['tokenizationError', 'loadingToken', 'type', 'pricing']),
    ...mapGetters('subscription', ['validPersonalInfo', 'subscriptionPrice', 'yearlySavingAgainstMonthlyPercentage',
      'formattedFirstBillingDate', 'subscriptionTypSelectedText']),
    ...mapState('benefits', ['benefitId', 'benefitType', 'benefitAmount', 'benefitPromotion', 'benefitPercentage']),
    ...mapGetters('benefits', ['BENEFIT_TYPES', 'discountedPrice', 'monthsText']),
    mainTitleText() {
      if (this.benefitId) {
        return `פרטי תשלום למימוש ההטבה ולרכישת מינוי ${this.subscriptionTypSelectedText}`;
      }
      return `איזה כיף שממשיכים לצמוח יחד עם מינוי ${this.subscriptionTypSelectedText}!`;
    },
    price() {
      if (this.isPercentageDiscountBenefit) {
        return this.discountedPrice;
      }
      return this.subscriptionPrice;
    },
    paymentTitle() {
      return this.isGenericFreeMonthBenefit
        ? 'לאישור ושמירת אמצעי תשלום'
        : 'לתשלום מאובטח';
    },
    discountPercentageAmount() {
      if (this.isPercentageDiscountBenefit) {
        return money.formatAmountWithFraction(this.benefitPercentage, 0);
      }
      if (this.type === SUBSCRIPTION_TYPES.YEARLY) {
        return money.formatAmountWithFraction(this.yearlySavingAgainstMonthlyPercentage, 0);
      }
      return null;
    },
  },
  methods: {
    ...mapActions('subscription', ['createSubscriptionV2', 'fetchSubscription']),
    ...mapActions('subscriptionState', ['fetchSubscriptionState']),
    backToCashflow() {
      router.push({ path: '/sr' });
    },
    async onPaymentClicked() {
      Segment.trackUserGot(
        'CreditCardPaymentClicked',
        { SubscriptionType: this.type, subscriptionVersion: 'v2' },
      );
      this.subscriptionButtonClicked = true;
      try {
        MetricsApi.sendCounter('subscription.paymentButton.clicked', { type: 'new' });
        await this.createSubscriptionV2({ percentageDiscount: this.isPercentageDiscountBenefit });
      } finally {
        await this.fetchSubscription();
        await this.fetchSubscriptionState();
        this.subscriptionButtonClicked = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
  .credit-card-v2 {

    .credit-card-details { // TODO temp change. when v2 will take over completely I will set this design in the component itself
      ::v-deep .title {
        margin-top: 32px;
        margin-bottom: 32px;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
      }
    }

      .link {
        cursor: pointer;
        color: $riseup_blue;
        text-align: center;
      }

    .payment-details {
      margin-bottom: 40px;
    }
  }
</style>
