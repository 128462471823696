<template>
  <div class="edit-account-nickname">
    <div class="name-description">{{ description }}</div>
    <ValidationObserver ref="observer" slim>
      <edit-text-input-field ref="nameInput" :field-type="'text'"
                             :field-value="nickname"
                             :error="nameAlreadyUsedErrorMessage || errorMsg"
                             :validations="'max-length:16'"
                             :update-input="edit"/>
    </ValidationObserver>
  </div>
</template>

<script>

import EditTextInputField from '@/base-components/card-parts/EditTextInputField.vue';
import _ from 'lodash';

export default {
  name: 'EditAccountNickname',
  components: {
    EditTextInputField,
  },
  props: {
    nickname: String,
    account: Object,
    nameAlreadyInUseError: Boolean,
    credsType: String,
    raiseError: Function,
    resetError: Function,
    updateNickname: Function,
  },
  data() {
    return {
      errorMsg: '',
    };
  },
  computed: {
    nameAlreadyUsedErrorMessage() {
      return this.nameAlreadyInUseError ? 'כבר השתמשת בשם הזה' : null;
    },
    description() {
      return this.credsType === 'card' ? 'איך לקרוא לכרטיס?' : 'איך לקרוא לחשבון?';
    },
    disabled() {
      return !!this.errorMsg || _.isEmpty(this.nickname) || this.nickname === this.account.accountNickname;
    },
  },
  methods: {
    edit(value) {
      if (value.length > 16) {
        this.errorMsg = 'שם יכול להכיל עד 16 תווים';
        this.raiseError();
        return;
      }
      this.updateNickname(this.account.accountNumberPiiId, value);
      this.errorMsg = '';
      this.resetError();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '~@riseupil/base-ui/src/scss/typography';
  @import '../../../scss/mixins';

  .edit-account-nickname {
    margin-top: 20px;

    .name-description {
      font-size: 16px;
      font-weight: $ri-font-weight-bold;
      color: $riseup_black;
      margin-bottom: 20px;
    }
  }
</style>
