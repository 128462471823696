<template>
  <bottom-sheet-overflow-button :bottom-sheet-component="sheetComponent" :menu-items="menuItems"
                                :component-props="{ menuItems, sheetProps: {disabled: isLoading} }" :menuButtonColor="menuButtonColor"/>
</template>

<script>
import Segment from '@/Segment';
import BaseUi from '@riseupil/base-ui';
import ClickOutside from '@/directives/click-outside';
import { mapActions, mapGetters } from 'vuex';
import DeleteCredsPopup from '@/base-components/credentials/credentials-list/DeleteCredsPopup';
import UpdateCredsNamePopup from '@/base-components/credentials/credentials-list/UpdateCredsNamePopup';
import UpdateAccountNamePopup from '@/base-components/credentials/credentials-list/UpdateAccountNamePopup';
import AggregatorApi from '@/api/AggregatorApi';
import DDLogs from '@/DDLogs';
import BottomSheetOverflowButton from '@/base-components/BottomSheetOverflowButton';
import AddCredentialsFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow';
import AgentsUtls from '@/utils/agentsUtils';
import SimpleBottomSheetMenu from '@/base-components/SimpleBottomSheetMenu';
import _ from 'lodash';
import CredentialsApi from '@/api/CredentialsApi';
import BrowserInfo from '@/mixins/BrowserInfo.vue';

export default {
  name: 'CredentialsMenu',
  directives: {
    ClickOutside,
  },
  components: {
    BottomSheetOverflowButton,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
  },
  mixins: [BrowserInfo],
  data() {
    return {
      isLoading: false,
      sheetComponent: SimpleBottomSheetMenu,
      menuButtonColor: BaseUi.Colors.riseupWhite,
      deleteItem: {
        text: 'מחיקת חיבור',
        onClick: this.emitDeleteCredsPopup,
        iconProps: {
          iconName: 'delete',
          fill: true,
        },
      },
      editItem: {
        text: 'עריכת שם החיבור',
        onClick: this.emitUpdateCredsPopup,
        iconProps: {
          iconName: 'edit',
          fill: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters('credentials', ['enableCredentialsActions']),
    ...mapGetters('featureFlags', ['enableAccountNickname']),
    isOpenBanking() {
      return this.source && !!this.source.openBankingConsentId;
    },
    consentRenewal() {
      return `לחידוש ההסכמה ב${this.sourceName}`;
    },
    sourceName() {
      return this.source.sourceName === 'לאומי קארד' ? 'מקס' : this.source.sourceName;
    },
    editAccountNicknameText() {
      return this.source.type === 'card' ? 'עריכת שם הכרטיס' : 'עריכת שם החשבון';
    },
    editAccountNicknameIcon() {
      return this.source.type === 'card' ? 'credit_card' : 'account_balance';
    },
    enableConsentRenewal() {
      return this.enableCredentialsActions || (this.source.buttons && this.source.buttons.displayReconnectConsentBtn);
    },
    renewConsentItem() {
      return this.isOpenBanking && {
        text: this.consentRenewal,
        onClick: this.renewConsent,
        disabled: this.source.displayIncidentTooltip,
        loading: this.isLoading,
        keepOpenOnClick: true,
        iconProps: {
          iconName: 'open_in_new',
        },
      };
    },
    editAccountNicknameItem() {
      return {
        text: this.editAccountNicknameText,
        onClick: this.emitAccountNicknamePopup,
        iconProps: {
          iconName: this.editAccountNicknameIcon,
          fill: false,
        },
      };
    },
    menuItems() {
      if (this.enableAccountNickname) {
        return _.compact([
          this.renewConsentItem,
          this.editItem,
          this.editAccountNicknameItem,
          this.deleteItem,
        ]);
      }
      return _.compact([
        this.renewConsentItem,
        this.editItem,
        this.deleteItem,
      ]);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    async renewConsent() {
      if (this.source.openBanking.allowDesktop === false && !AgentsUtls.isMobile()) {
        this.openModal({
          component: AddCredentialsFlow,
          props: {
            sourceNameToAdd: this.source.sourceName,
          },
          popupAlignment: 'full-screen',
        });
        Segment.trackUserInteraction(
          'RenewConsentOBKClickedFormNotAllowedDesktop',
          { credentialsId: this.source.credentialsId, source: this.source.sourceName },
        );
      } else {
        this.isLoading = true;
        Segment.trackUserInteraction('RenewConsentOBKClicked', {
          credentialsId: this.source.credentialsId,
          source: this.source.sourceName,
        });
        const url = await AggregatorApi.updateConsent(
          this.source.openBanking.aggregatorBankId,
          this.source.openBankingConsentId,
          this.source.credentialsId,
        );
        await CredentialsApi.createOBKConnectionRequestEvent({
          consentId: this.source.openBankingConsentId,
          previousConsentId: this.source.previousConsentId,
          sourceName: this.source.sourceName,
          browserInfo: await this.getBrowserParams(),
        });
        DDLogs.log('Redirecting to OpenBanking', { url });
        window.location.href = url;
        this.isLoading = false;
      }
    },
    emitDeleteCredsPopup() {
      Segment.trackUserInteraction('SectionMenu_DeleteCredsClicked');
      this.openModal({
        component: DeleteCredsPopup,
        props: { source: this.source },
      });
    },
    emitUpdateCredsPopup() {
      Segment.trackUserInteraction('SectionMenu_UpdateCredsClicked');
      this.openModal({
        component: UpdateCredsNamePopup,
        props: { source: this.source },
      });
    },
    emitAccountNicknamePopup() {
      Segment.trackUserInteraction('SectionMenu_AccountNicknameClicked');
      this.openModal({
        component: UpdateAccountNamePopup,
        props: { source: this.source },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
