import Vue from 'vue';
import Vuex from 'vuex';
import insights from '@/store/modules/insights';
import cities from '@/store/modules/cities';
import demographicInfo from '@/store/modules/demographic-info';
import benchmark from '@/store/modules/benchmark';
import celebrateSaving from '@/store/modules/savings/celebrateSaving';
import supermarketStory from '@/store/modules/supermarket-story';
import sharePage from '@/store/modules/sharePage';
import ambassadorsTouchPoints from '@/store/modules/ambassadorsTouchPoints';
import credentialsNew from '@/store/modules/credentialsNew';
import introductionFromOtherProducts from '@/store/modules/introductionFromOtherProducts';
import anonymizeData from '@/store/modules/anonymize-data';
import oneOffOfferings from '@/store/modules/oneOffOfferings';
import suggestOfferingsPopup from '@/store/modules/suggestOfferingsPopup';
import dormantAfterOnItChallengePopup from '@/store/modules/dormantAfterOnItChallengePopup';
import mobileAppPrompt from '@/store/modules/mobileAppPrompt';
import bulkActions from '@/store/modules/bulk-actions';
import accountInvestigationQuestions from './modules/accountInvestigationQuestions';
import accountInvestigationOnboarding from './modules/accountInvestigationOnboarding';
import mergeInvestigationQuestions from './modules/mergeInvestigationQuestions';
import suspiciousGroupJoin from './modules/suspiciousGroupJoin';
import credentials from './modules/credentials';
import creditCardExpenses from './modules/creditCardExpenses';
import onboardPreferences from './modules/onboardPreferences';
import session from './modules/session';
import inviteSession from './modules/inviteSession';
import cashflowView from './modules/cashflowView';
import signup from './modules/signup';
import ambassadors from './modules/ambassadors';
import genericError from './modules/genericError';
import activationSurvey from './modules/surveys/activationSurvey';
import churnSurvey from './modules/surveys/churnSurvey';
import memberInvite from './modules/memberInvite';
import onboarding from './modules/onboarding';
import subscription from './modules/subscription';
import payment from './modules/payment';
import subscriptionState from './modules/subscriptionState';
import optimisticChange from './modules/cashflow-app/optimisticChange';
import editCashflow from './modules/cashflow-app/editCashflow';
import editTransaction from './modules/cashflow-app/editTransaction';
import splitTransaction from './modules/cashflow-app/splitTransaction';
import applicationState from './modules/applicationState';
import churn from './modules/churn';
import loader from './modules/loader';
import websocket from './modules/websocket';
import balance from './modules/balance';
import login from './modules/login';
import leadQualification from './modules/leadQualification';
import referral from './modules/referral';
import incomeInvestigationQuestions from './modules/incomeInvestigationQuestions';
import expenseInvestigationQuestions from './modules/expenseInvestigationQuestions';
import onboardingState from './modules/onboardingState';
import promotions from './modules/promotions';
import benefits from './modules/benefits';
import createTrackingCategory from './modules/create-tracking-category';
import contentLoader from './modules/contentLoader';
import share from './modules/share';
import dynamicSavings from './modules/savings/dynamicSavings';
import transactionStories from './modules/transactionStories';
import timeLimitedOffers from './modules/timeLimitedOffers';
import savings from './modules/savings/savings';
import modalRootStore from './modules/modal-root-store';
import challenge from './modules/challenge';
import savingZone from './modules/savings/saving-zone';
import actions from './modules/actions';
import featureFlags from './modules/feature-flags';
import pocketMoney from './modules/pocket-money';
import story from './modules/story';
import planAhead from './modules/planAhead';
import riseupGiftcard from './modules/riseupGiftcard';
import referrals from './modules/referrals';
import challenges from './modules/challenges';
import advisory from './modules/advisory';
import communicationState from './modules/communicationState';
import assetsAndLiabilities from './modules/assetsAndLiabilities';
import imOnIt from './modules/imOnIt';
import bottomCornerPopup from './modules/bottomCornerPopup';
import appHeader from './modules/appHeader';
import cashflowSearch from './modules/cashflowSearch';
import signupOfferings from './modules/signupOfferings';
import customerProgress from './modules/customerProgress';
import dataCenter from './modules/dataCenter';
import ots from './modules/ots';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accountInvestigationQuestions,
    accountInvestigationOnboarding,
    anonymizeData,
    mergeInvestigationQuestions,
    suspiciousGroupJoin,
    credentials,
    creditCardExpenses,
    onboardPreferences,
    session,
    onboarding,
    inviteSession,
    cashflowView,
    signup,
    ots,
    ambassadors,
    login,
    genericError,
    activationSurvey,
    churnSurvey,
    memberInvite,
    subscription,
    subscriptionState,
    optimisticChange,
    editCashflow,
    editTransaction,
    applicationState,
    churn,
    loader,
    contentLoader,
    websocket,
    leadQualification,
    balance,
    referral,
    incomeInvestigationQuestions,
    expenseInvestigationQuestions,
    onboardingState,
    promotions,
    benefits,
    createTrackingCategory,
    bulkActions,
    insights,
    cities,
    share,
    dynamicSavings,
    transactionStories,
    savings,
    modalRootStore,
    challenge,
    savingZone,
    actions,
    benchmark,
    celebrateSaving,
    supermarketStory,
    splitTransaction,
    demographicInfo,
    sharePage,
    featureFlags,
    pocketMoney,
    story,
    planAhead,
    ambassadorsTouchPoints,
    suggestOfferingsPopup,
    riseupGiftcard,
    referrals,
    credentialsNew,
    challenges,
    introductionFromOtherProducts,
    advisory,
    communicationState,
    assetsAndLiabilities,
    imOnIt,
    bottomCornerPopup,
    appHeader,
    cashflowSearch,
    timeLimitedOffers,
    payment,
    signupOfferings,
    oneOffOfferings,
    customerProgress,
    dormantAfterOnItChallengePopup,
    dataCenter,
    mobileAppPrompt,
  },
});
