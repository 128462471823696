<template>
  <div class="transaction-insight-popup">
    <insight-details-popup :insight-popup-explanation="insightPopupTitle"
                           :insight-popup-title="insightPopupTitle"
                           :insight-prefix="insightPrefix"
                           :cashflow-category="cashflowCategory"
                           :insight="insight"
                           :buttons="buttons"
                           :showFeedbackFooter="showFeedbackFooter"
                           :back-action="backAction"
                           :close-action="close">
      <template v-slot:top-content>
        <div class="insight-prefix">
          {{ insightPrefix }}
        </div>
        <transaction-details :transaction="insight" :use-content-details="useContentDetails">
          <template v-slot:transaction-details-content>
            <div>{{detailsContent}}</div>
          </template>
        </transaction-details>
      </template>
      <template v-slot:bottom-content>
        <div class="insight-popup-title">{{ insightPopupTitle }}</div>
        <div class="insight-popup-explanation">{{ insightPopupExplanation }}</div>
      </template>
      <template v-slot:footer>
        <slot name="footer"></slot>
      </template>
    </insight-details-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Segment from '@/Segment';
import InsightDetailsPopup from './InsightDetailsPopup';
import TransactionDetails from '../../cashflow-view/current-month/components/cashflow-edit/edit-transaction/TransactionDetails.vue';

export default {
  name: 'TransactionInsightPopup',
  components: {
    TransactionDetails,
    InsightDetailsPopup,
  },
  props: {
    insight: { type: Object, required: true },
    cashflowCategory: { type: String, required: true },
    insightPrefix: { type: String, required: true },
    insightPopupTitle: { type: String, required: true },
    insightPopupExplanation: { type: String, required: false },
    buttons: { type: Array, required: false },
    showFeedbackFooter: { type: Boolean, default: false },
    backAction: { type: Function, required: false },
    detailsContent: { type: String, required: false },
  },
  created() {
    this.sendSegmentEvent('InsightPopupShown');
  },
  computed: {
    ...mapGetters('editTransaction', ['categoryToVariant']),
    headerVariant() {
      return this.categoryToVariant[this.cashflowCategory];
    },
    useContentDetails() {
      return !!this.detailsContent;
    },
  },
  methods: {
    sendSegmentEvent(eventName, extraProps = {}) {
      Segment.trackUserInteraction(eventName, { ...this.insight, ...extraProps });
    },
    close() {
      this.sendSegmentEvent('InsightPopupCloseClicked');
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.transaction-insight-popup {
  .insight-prefix {
    margin-bottom: 16px;
    line-height: 11px;
    font-weight: bold;
  }

  .insight-popup-title {
    margin-bottom: 16px;
    font-size: 22px;
    line-height: 28px;
    font-weight: bold;
  }

  .insight-popup-explanation {
    font-size: 18px;
    line-height: 24px;
  }
}
</style>
