<script>
import featureFlag from '@/api/featureFlag';

const sendingPlaforms = {
  WHATSAPP: 'whatsapp',
  SMS: 'sms',
};

export default {
  name: 'OtpMixin',
  data() {
    return {
      sendCodeViaSMS: false,
      defaultSendPlatform: sendingPlaforms.WHATSAPP,
    };
  },
  async created() {
    this.sendCodeViaSMS = (await featureFlag.getAnonymousTreatment('sms-otp', '000')) === 'on';
  },
  methods: {
  },
  computed: {
    otpSentVia() {
      return this.sendCodeViaSMS ? 'בהודעה' : 'בוואטסאפ';
    },
    sendPlatform() {
      return this.sendCodeViaSMS ? sendingPlaforms.SMS : sendingPlaforms.WHATSAPP;
    },
  },
};

</script>
