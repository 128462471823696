const MOBILE_MESSAGES_NAME = {
  PAGE_MOUNTED: 'pageMounted',
  LOGOUT: 'logout',
};

function dispatchMessageToMobile(name, detail) {
  if (window.riseupPlatform === 'flutter_web') {
    window.dispatchEvent(new CustomEvent(
      name,
      { detail },
    ));
  }
}

export default { dispatchMessageToMobile, MOBILE_MESSAGES_NAME };
