<template>
  <div class="credentials-account-list">
    <connected-credentials-name :source="source"/>
    <div v-if="accounts" class="source-accounts">
      <div class="account" v-for="(account, index) in orderedAccounts" :key="account.accountNumberPiiValue" :class="{ excluded: account.isExcluded }">
        <connected-account-details :index="index" :account="account" :type="source.type"/>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import ConnectedCredentialsName from './ConnectedCredentialsName.vue';
import ConnectedAccountDetails from './ConnectedAccountDetails.vue';

export default {
  name: 'ConnectedCredentialsDetails',
  components: {
    ConnectedCredentialsName,
    ConnectedAccountDetails,
  },
  props: {
    source: Object,
  },
  computed: {
    sourceName() {
      return this.source.displayName;
    },
    name() {
      return this.source.name;
    },
    accounts() {
      return this.source.accounts;
    },
    type() {
      return this.source.type;
    },
    orderedAccounts() {
      return _.sortBy(this.accounts, account => !!account.isExcluded);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../scss/mixins';
</style>
