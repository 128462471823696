import { client } from '../http';

export default {
  async getOTSResult(jobId) {
    const response = await client.get(`/api/ots-results/${jobId}`);
    return response.data;
  },
  async createLLMJob({ flowConfigId, promptId, executeNow }) {
    const response = await client.post(
      '/api/llm-jobs',
      { flowConfigId, promptId, executeNow },
    );
    return response.data;
  },
  async getLatestPromptJob() {
    const response = await client.get(
      '/api/llm-jobs/latest',
    );
    return response.data;
  },
  async activateLLMJob(jobId, triggerScrape = false) {
    const response = await client.post(`/api/llm-jobs/activate/${jobId}`, { triggerScrape });
    return response.data;
  },
  async initOTSObState() {
    const response = await client.post(
      '/api/onboarding-state/init/ots-il',
    );
    return response.data;
  },
  async getJobByParams({ flowConfigId, promptId }) {
    try {
      const params = new URLSearchParams({
        flowConfigId,
        promptId,
      });
      const response = await client.get(`/api/llm-jobs/by-params?${params}`);
      return response.data;
    } catch (ex) {
      return {};
    }
  },
};
