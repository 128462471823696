import { client, clientWithoutErrorHandling } from '../http';

import consts from './constants';
import credentialsEncryptor from '../utils/credentialsEncryptor';

async function fetchCredentials() {
  const response = await client.get('/api/credentials-info');
  return response.data;
}

async function fetchLoadingCredentialsState() {
  const response = await client.get('/api/loading-credentials-state/');
  return response.data;
}

async function updateCredentials({ credentialsId, credentials, name, status, isSync }) {
  const encryptedCredentials = safeEncrypt(credentials, { name, credentialsId });
  return await client.put(`/api/credentials/${credentialsId}`, { encryptedCredentials, name, user: consts.CUSTOMER_USER, status, isSync });
}

async function saveCredentials({ sourceName, credentials, name, sourceType, provider }) {
  const encryptedCredentials = safeEncrypt(credentials, { name, sourceName });
  const response = await client.post(
    '/api/credentials',
    { encryptedCredentials, name, user: consts.CUSTOMER_USER, sourceName, sourceType, provider },
  );
  return response.data;
}

async function deleteCredentials({ credentialsId }) {
  return await client.delete(`/api/credentials/${credentialsId}`, { user: consts.CUSTOMER_USER });
}

async function checkCredentials({ sourceName, credentials, credentialsId, renew, provider }) {
  const encryptedCredentials = safeEncrypt(credentials, { sourceName });
  const response = await clientWithoutErrorHandling.post('/api/check-credentials', {
    source: sourceName,
    encryptedCredentials,
    credentialsId,
    renew,
    provider,
  });
  return response.data;
}

async function fetchCredsToAccounts() {
  const response = await client.get('/api/creds-to-accounts');
  return response.data;
}

async function fetchMissingCCsAfterOBKMigration() {
  const response = await client.get('/api/non-migrated-to-obk-ccs');
  return response.data;
}

function safeEncrypt(object, logData) {
  const encrypted = credentialsEncryptor.encrypt(object);
  if (encrypted === 'false') {
    throw new Error(`Failed encrypting object ${JSON.stringify(logData)}`);
  }
  return encrypted;
}

async function logCredsCheck(credentialsId, isValid, reason) {
  await client.post('/api/credentials-events/immediate-creds-check', { credentialsId, isValid, reason });
}

async function isNameAvailable(sourceName, name) {
  const response = await client.post('/api/is-creds-name-available', { sourceName, name });
  return response.data.isAvailable;
}

async function createOBKConnectionRequestEvent({ consentId, previousConsentId, sourceName, browserInfo }) {
  const response = await client.post('/api/obk-connection-request-event/', { consentId, previousConsentId, sourceName, browserInfo });
  return response.data;
}

async function createOBKConnectionSuccessEvent({ consentId, sourceName, browserInfo }) {
  const response = await client.post('/api/obk-connection-success-event/', { consentId, sourceName, browserInfo });
  return response.data;
}

async function fetchCredentialsSettings() {
  const response = await client.get('/api/credentials-settings');
  return response.data;
}

async function fetchCredentialsIdsToUpdate() {
  const response = await client.get('/api/credentials-metadata/credentials-ids-to-update');
  return response.data;
}

async function setAccountNickname(accountNumberPiiId, accountNickname) {
  const response = await client.post(
    '/api/account-nickname',
    { accountNumberPiiId, accountNickname },
  );
  return response.data;
}

export default {
  fetchCredentials,
  updateCredentials,
  deleteCredentials,
  saveCredentials,
  checkCredentials,
  fetchCredsToAccounts,
  logCredsCheck,
  isNameAvailable,
  fetchLoadingCredentialsState,
  createOBKConnectionRequestEvent,
  createOBKConnectionSuccessEvent,
  fetchMissingCCsAfterOBKMigration,
  fetchCredentialsSettings,
  fetchCredentialsIdsToUpdate,
  setAccountNickname,
};
