<template>
  <toaster @close-toaster="closeSummaryToaster">
    <template v-slot:text>
      <span>{{ text }}</span>
    </template>
  </toaster>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Toaster from '../../../../../../base-components/Toaster';

export default {
  name: 'BalanceDateUpdatedToaster',
  components: {
    Toaster,
  },
  props: {
  },
  computed: {
    text() {
      return 'התאריך עודכן';
    },
  },
  methods: {
    closeSummaryToaster() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
