import _ from 'lodash';
import { clientWithoutErrorHandling } from '@/http';
import DDLogs from '../DDLogs';
import anonymousSession from '../anonymousSession';

const DEFAULT_OFF_TREATMENT = 'off';

function _isNetworkRedirectError(error) {
  return _.get(error, 'request.__sentry_xhr__.status_code') === 0;
}

export default {
  async getTreatment(flagName) {
    try {
      DDLogs.log(`Getting feature flag treatment - ${flagName}`, { flagName });
      const response = await clientWithoutErrorHandling.get(`/api/feature-flag/${flagName}`, {});
      return response.data;
    } catch (error) {
      this._errorHandler(error, flagName);
      return DEFAULT_OFF_TREATMENT;
    }
  },
  async getTreatments(flagNames) {
    try {
      DDLogs.log('Getting multiple feature flag treatments', { flagNames });
      const response = await clientWithoutErrorHandling.post('/api/feature-flags', { flagNames });
      return response.data;
    } catch (error) {
      this._errorHandler(error, flagNames);
      return _.times(flagNames.length, () => DEFAULT_OFF_TREATMENT);
    }
  },
  async getTreatmentPartialAuth(flagName) {
    try {
      DDLogs.log(`Getting feature flag treatment - ${flagName}`, { flagName });
      const response = await clientWithoutErrorHandling.get(`/api/partial-auth/feature-flag/${flagName}`, {});
      return response.data;
    } catch (error) {
      this._errorHandler(error, flagName);
      return DEFAULT_OFF_TREATMENT;
    }
  },
  async getTreatmentInviteAuth(flagName) {
    try {
      DDLogs.log(`Getting feature flag treatment - ${flagName}`, { flagName });
      const response = await clientWithoutErrorHandling.get(`/api/invite-auth/feature-flag/${flagName}`, {});
      return response.data;
    } catch (error) {
      this._errorHandler(error, flagName);
      return DEFAULT_OFF_TREATMENT;
    }
  },
  async getAnonymousTreatment(flagName, id) {
    const anonymousId = id || anonymousSession.getId();
    try {
      DDLogs.log('Fetching feature flag treatment', { flagName, anonymousId });
      const response = await clientWithoutErrorHandling.get(`/api/no-auth/anonymous-feature-flag/${flagName}/${anonymousId}`, {});
      return response.data;
    } catch (error) {
      if (_isNetworkRedirectError(error)) {
        DDLogs.log('Failed to get anonymous feature flag treatment - known network redirect error', { error, flagName, anonymousId });
      } else {
        DDLogs.error('Failed to get anonymous feature flag treatment', { error, flagName, anonymousId });
      }
      return 'control';
    }
  },
  async getTreatmentAsJson(flagName) {
    try {
      DDLogs.log(`Getting dynamic feature flag treatment - ${flagName}`, { flagName });
      const response = await clientWithoutErrorHandling.get(`/api/dynamic-feature-flag/${flagName}`, {});
      return JSON.parse(response.data.config);
    } catch (error) {
      this._errorHandler(error, flagName);
      return {};
    }
  },
  async getTreatmentsAsJson(flagNames) {
    try {
      DDLogs.log('Getting multiple dynamic feature flags treatments', { flagNames });
      const treatmentResults = await clientWithoutErrorHandling.post('/api/dynamic-feature-flags', { flagNames });

      Object.keys(treatmentResults.data).forEach(key => {
        treatmentResults.data[key] = JSON.parse(treatmentResults.data[key].config);
      });

      return treatmentResults.data;
    } catch (error) {
      this._errorHandler(error, flagNames);
      return {};
    }
  },
  _errorHandler(error, flagName) {
    if (error.response && error.response.status === 403) {
      DDLogs.error(`Failed to get feature flag treatment - got 403 forbidden - ${flagName}`, {
        error,
        flagName,
      });
    } else if (_isNetworkRedirectError(error)) {
      DDLogs.log('Failed to get feature flag treatment - known network redirect error', {
        error,
        flagName,
      });
    } else {
      DDLogs.error('Failed to get feature flag treatment', {
        error,
        flagName,
      });
    }
  },

};
