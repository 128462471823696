<template>
  <div class="row-content">
    <img class="logo" v-if="disabled || account.isExcluded" src="/images/onboarding/icons/bank-icon-disabled.svg">
    <img class="logo" v-else src="/images/onboarding/icons/bank-icon.svg">
    <div class="account-number" :class="{ 'disabled': disabled || account.isExcluded }">
      <span>{{ account.accountNumberPiiValue }}</span>
      <span v-if="account.isExcluded" class="disabled">לא כלול</span>
      <span v-else-if="account.accountNickname" class="nickname">{{ account.accountNickname }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'connectedBankAccount',
  props: {
    account: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../scss/mixins';

.row-content {
  height: 72px;
  @include responsive-padding;
  display: flex;
  flex-direction: row;
  align-items: center;

  .logo {
    width: 24px;
    height: 24px;
  }

  .account-number {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
    font-size: 16px;
    font-weight: bold;

    &.disabled {
      color: $riseup_gray_2
    }

    .nickname {
      font-weight: normal;
    }
  }
}

</style>
