import { client } from '../http';

async function fetchAgamResults() {
  const response = await client.get('/api/agam');
  return response.data;
}

async function downloadAgamReport() {
  window.location.href = '/api/download-agam-report';
}

export default {
  fetchAgamResults,
  downloadAgamReport,
};
