import _ from 'lodash';

const ACTIONS = {
  MOVE: 'move',
};

const state = {
  action: null,
  availableTransactions: [],
};

const getters = {
  selectedTransactions: state => _.filter(state.availableTransactions, t => t.isChecked),
};

const actions = {
  async setBulkActions({ commit }, { action = ACTIONS.MOVE, availableTransactions }) {
    commit('setAction', action);
    commit('setAvailableTransactions', _.cloneDeep(availableTransactions));
  },
  async resetBulkActions({ commit }) {
    commit('setAction', null);
    commit('setAvailableTransactions', []);
  },
  async toggleTransaction({ commit }, { transactionId }) {
    const transaction = state.availableTransactions.find(t => t.transactionId === transactionId);
    transaction.isChecked = !transaction.isChecked;
  },
};

const mutations = {
  setAction(state, action) {
    state.selectedAction = action;
  },
  setAvailableTransactions(state, availableTransactions) {
    state.availableTransactions = availableTransactions;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
