import Vue from 'vue';
import CustomersApi from '@/api/CustomersApi';
import SignupApi from '../../api/SignupApi';
import LoginApi from '../../api/LoginApi';
import ReferralsApi from '../../api/referrals';
import Segment from '../../Segment';
import phoneNumber from '../../utils/phoneNumber';

const inviteMemberFlowSteps = {
  enterDetails: 'enter-details',
  enterCode: 'enter-code',
  done: 'done',
};

const errorTypes = {
  memberAlreadyExists: 'MEMBER_ALREADY_EXISTS',
  memberTryingToJoinOwnAccountError: 'MEMBER_TRYING_TO_JOIN_OWN_ACCOUNT',
};

const state = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  enableMarketingEmails: '',
  currentFlowStep: inviteMemberFlowSteps.enterDetails,
  canAutoChurnExistingCustomer: false,
  errors: {
    generateCode: '',
    createMember: '',
    authenticate: '',
  },
};

const getters = {
  inviteMemberFlowSteps: () => inviteMemberFlowSteps,
  errorTypes: () => errorTypes,
};

const actions = {
  async generateCode({ dispatch, state, commit }, { force = false, sendPlatform }) {
    if (phoneNumber.validatePhoneNumber(state.phoneNumber)) {
      try {
        await LoginApi.generateCode({ phoneNumber: state.phoneNumber, force, sendPlatform });
      } catch (e) {
        dispatch('catchServerError', { e, functionName: 'generateCode' });
      }
    } else {
      commit('setError', { error: 'INVALID_PHONE_NUMBER', functionName: 'generateCode' });
      throw new Error('Invalid phone number');
    }
  },
  forceGenerateCode({ dispatch }, sendPlatform) {
    dispatch('generateCode', { force: true, sendPlatform });
  },
  async createMember({ dispatch, commit, state }) {
    try {
      await SignupApi.createMember({
        firstName: state.firstName,
        lastName: state.lastName,
        phoneNumber: state.phoneNumber,
        email: state.email,
        enableMarketingEmails: state.enableMarketingEmails,
      });
    } catch (e) {
      await dispatch('handleCreateMemberError', e);
    }
  },
  async handleCreateMemberError({ dispatch, commit }, e) {
    const [products, hasMultipleMembers, hasReferralEvents] = await Promise.all([
      CustomersApi.getProducts(),
      CustomersApi.hasMultipleMembers(),
      ReferralsApi.hasReferralEvents(),
    ]);
    const eligibleForAutoChurn = !hasMultipleMembers && !products?.challenges && !products?.saving && !hasReferralEvents;
    const tryingToJoinOwnAccount = e.response.data === errorTypes.memberTryingToJoinOwnAccountError;
    Segment.trackUserGot('ExistingCustomerTryingToJoinAnotherAccount', {
      products,
      hasMultipleMembers,
      hasReferralEvents,
      canSelfImmediateChurn: eligibleForAutoChurn,
      tryingToJoinOwnAccount,
    });
    commit('setCanAutoChurnExistingCustomer', eligibleForAutoChurn && !tryingToJoinOwnAccount);
    dispatch('catchServerError', {
      e,
      functionName: 'createMember',
    });
  },
  async authenticate({ commit, state, dispatch }, code) {
    commit('setCode', { code });
    try {
      await LoginApi.authenticate({ phoneNumber: state.phoneNumber, code });
    } catch (e) {
      dispatch('catchServerError', { e, functionName: 'authenticate' });
    }
  },
  catchServerError({ commit }, { e, functionName }) {
    if (e.response && e.response.status === 400) {
      commit('setError', { error: e.response.data, functionName });
    } else {
      commit('setError', { error: 'UNKNOWN_ERROR', functionName });
    }
    Segment.trackUserGot('MemberInviteError', { error: e });
    throw e;
  },
};

const mutations = {
  setCurrentFlowStep(state, currentFlowStep) {
    state.currentFlowStep = currentFlowStep;
  },
  setFirstName(state, firstName) {
    state.firstName = firstName;
  },
  setLastName(state, lastName) {
    state.lastName = lastName;
  },
  setPhoneNumber(state, phoneNumber) {
    state.phoneNumber = phoneNumber;
  },
  setError(state, { error, functionName }) {
    Vue.set(state.errors, functionName, error);
  },
  resetError(state, functionName) {
    Vue.set(state.errors, functionName, '');
  },
  setEmail(state, email) {
    state.email = email; // eslint-disable-line
  },
  setEnableMarketingEmails(state, enableMarketingEmails) {
    state.enableMarketingEmails = enableMarketingEmails; // eslint-disable-line
  },
  setCanAutoChurnExistingCustomer(state, canAutoChurnExistingCustomer) {
    state.canAutoChurnExistingCustomer = canAutoChurnExistingCustomer;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
